<template>
  <div
    id="table__company-locations"
    class="rv-default-data-list data-list-container"
  >
    <vs-table
      ref="table"
      pagination
      :max-items="perPage"
      search
      :data="locations || []"
      :no-data-text="'No Locations'"
      @selected="(location) => $emit('selected', location)"
    >
      <template #header>
        <div class="flex items-center flex-grow justify-between">
          <div class="flex items-center data-list-btn-container">
            <slot name="actions" />
          </div>

          <slot name="filters">
            <div class="cursor-pointer mr-4 items-per-page-handler">
              <vs-dropdown vs-trigger-click>
                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mr-2">
                    {{ currentPage * perPage - (perPage - 1) }} - {{ locations.length - currentPage * perPage > 0 ? currentPage * perPage : locations.length }} of {{ queriedItems }}
                  </span>
                  <feather-icon
                    icon="ChevronDownIcon"
                    svg-classes="h-4 w-4"
                  />
                </div>

                <vs-dropdown-menu>
                  <vs-dropdown-item @click="perPage = 4">
                    <span>4</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="perPage = 10">
                    <span>10</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="perPage = 15">
                    <span>15</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="perPage = 20">
                    <span>20</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </slot>
        </div>
      </template>

      <template slot="thead">
        <slot name="table-head">
          <vs-th
            sort-key="name"
            class="th__locations-name"
          >
            Location Name
          </vs-th>
          <vs-th
            sort-key="street_address"
            class="th__locations-address"
          >
            Street Address
          </vs-th>
          <vs-th
            sort-key="visit_rate"
            class="th__locations-progress"
          >
            <span class="w-full text-center">REPUTATION GRADE</span>
          </vs-th>
          <vs-th
            sort-key="completion_rate"
            class="th__locations-progress"
          >
            <span class="w-full text-center">COMPETITOR REVIEW SCORE</span>
          </vs-th>
          <vs-th
            sort-key="satisfaction_score"
            class="th__locations-progress"
          >
            <span class="w-full text-center">SATISFACTION SCORE</span>
          </vs-th>
          <vs-th
            sort-key="surveys_sent"
            class="th__locations-surveys"
          >
            <span class="w-full text-center">30-DAY SURVEYS</span>
          </vs-th>
          <vs-th class="th__locations-action">
            <span class="w-full text-center">ACTION</span>
          </vs-th>
        </slot>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr
            v-for="location in data"
            :key="location.id"
            :data="location"
          >
            <slot
              name="table-row"
              :location="location"
            />
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  name: 'RjLocationsList',

  props: {
    locations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      perPage: 15,
      isMounted: false,
    };
  },

  computed: {
    currentPage() {
      return this.isMounted ? this.$refs.table.currentx : 0;
    },

    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults?.length || 0 : this.locations.length;
    },
  },

  mounted() {
    this.isMounted = true;
  },
};
</script>
