<template>
  <vx-card>
    <vs-tabs alignment="fixed">
      <!-- Message One Tab -->
      <vs-tab label="Message #1">
        <rj-funnel-message-form
          ref="message1Form"
          :index="1"
          :funnel="funnel"
          :is-company-user="isCompanyUser"
        />
      </vs-tab>

      <!-- Message Two Tab -->
      <vs-tab label="Message #2">
        <rj-funnel-message-form
          ref="message2Form"
          :index="2"
          :funnel="funnel"
          :is-company-user="isCompanyUser"
        />
      </vs-tab>

      <!-- Message Three Tab -->
      <vs-tab label="Message #3">
        <rj-funnel-message-form
          ref="message3Form"
          :index="3"
          :funnel="funnel"
          :is-company-user="isCompanyUser"
        />
      </vs-tab>

      <!-- Survey Tab -->
      <vs-tab label="Survey">
        <rj-funnel-survey-form
          ref="surveyForm"
          :funnel="funnel"
          :company="company"
          :locations="locations"
          :is-company-user="isCompanyUser"
        />
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import RjFunnelSurveyForm from '@/views/common/funnels/partials/RjFunnelSurveyForm.vue';
import RjFunnelMessageForm from '@/views/common/funnels/partials/RjFunnelMessageForm.vue';

export default {
  name: 'RjFunnelContents',

  components: {
    RjFunnelSurveyForm,
    RjFunnelMessageForm,
  },

  props: {
    isCompanyUser: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    funnel() {
      return this.$store.getters['funnels/funnel'];
    },

    company() {
      return this.$store.getters['companies/company'];
    },

    locations() {
      const locations = this.$store.getters['locations/locations'];
      return [...locations].sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1));
    },
  },

  methods: {
    reset() {
      if (this.$refs.message1Form) {
        this.$refs.message1Form.reset();
      }
      if (this.$refs.message2Form) {
        this.$refs.message2Form.reset();
      }
      if (this.$refs.message3Form) {
        this.$refs.message3Form.reset();
      }
      if (this.$refs.surveyForm) {
        this.$refs.surveyForm.reset();
      }
    },
  },
};
</script>
