<template>
  <div>
    <img
      :src="preview"
      class="w-full object-cover"
    >

    <div class="px-2 py-4">
      <div>
        <p class="text-sm text-center font-bold leading-tight">
          {{ funnel.offerTitle }}
        </p>
        <p class="text-xs leading-tight text-center mt-1">
          {{ funnel.offerBody }}
        </p>
      </div>

      <p class="text-xs text-center font-bold leading-tight mt-3">
        Click to Share
      </p>

      <div class="flex text-center items-center justify-between py-2">
        <!-- Facebook Share -->
        <share-network
          v-if="networks.includes('Facebook')"
          tag="div"
          network="facebook"
          :url="ogShareUrl"
          :title="funnel.offerShareSubject"
          :description="funnel.offerShareBody"
          class="cursor-pointer"
        >
          <span
            class="block mx-auto w-8 h-8 flex items-center justify-center rounded-full text-white"
            style="background: #3b5998;"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
            </svg>
          </span>
          <span class="text-xs text-center">Facebook</span>
        </share-network>

        <!-- SMS Share -->
        <share-network
          v-if="networks.includes('SMS')"
          tag="div"
          network="sms"
          :url="shareUrl"
          :title="funnel.offerShareSubject"
          :description="funnel.offerShareBody"
          class="cursor-pointer"
        >
          <span
            class="block mx-auto w-8 h-8 flex items-center justify-center rounded-full text-white"
            style="background: #28c76f;"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z" />
            </svg>
          </span>
          <span class="text-xs text-center">SMS</span>
        </share-network>

        <!-- Email Share -->
        <share-network
          v-if="networks.includes('Email')"
          tag="div"
          network="email"
          :url="shareUrl"
          :title="funnel.offerShareSubject"
          :description="funnel.offerShareBody"
          class="cursor-pointer"
        >
          <span
            class="block mx-auto w-8 h-8 flex items-center justify-center rounded-full text-white"
            style="background: #3caec8;"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" />
            </svg>
          </span>
          <span class="text-xs text-center">Email</span>
        </share-network>

        <share-network
          v-if="networks.includes('Twitter')"
          tag="div"
          network="twitter"
          :url="shareUrl"
          :title="funnel.offerShareBody"
          class="cursor-pointer"
        >
          <span
            class="block mx-auto w-8 h-8 flex items-center justify-center rounded-full text-white"
            style="background: #1DA1F2;"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="currentColor"
            ><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" /></svg>
          </span>
          <span class="text-xs text-center">Twitter</span>
        </share-network>
      </div>

      <a
        href="#"
        class="text-gray-600 text-xs text-center block hover:underline"
        @click.prevent="offerDetailsDialog = true"
      >
        Offer Details
      </a>
    </div>

    <template v-if="offerDetailsDialog">
      <div class="flex justify-center items-center">
        <div
          class="absolute inset-0 w-full h-full bg-black bg-opacity-25"
          @click="offerDetailsDialog = false"
        />
        <div class="absolute top-2/4 w-11/12 transform -translate-y-1/2 bg-white rounded-lg z-10 m-4">
          <header class="flex items-center justify-between px-2 py-2 bg-gray-100 relative rounded-lg">
            <p class="text-xs font-bold text-primary">
              Offer Details
            </p>
            <span
              class="flex bg-white -mt-4 -mr-3 items-center justify-center text-primary w-6 h-6 rounded shadow-md z-20 cursor-pointer"
              @click="offerDetailsDialog = false"
            >
              <feather-icon
                icon="XIcon"
                svg-classes="w-4 h-4"
              />
            </span>
          </header>
          <div class="px-2 py-2 text-xs">
            <p>{{ funnel.offerDetails }}</p>
          </div>
          <footer class="border-t border-gray-300 py-2 mx-2 flex items-center justify-end">
            <vs-button
              size="small"
              @click="offerDetailsDialog = false"
            >
              Close
            </vs-button>
          </footer>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ShareNetwork } from 'vue-social-sharing';
import { httpBuildQuery } from '@/utils';

export default {
  name: 'RjReferralSurveyPreview',

  components: { ShareNetwork },

  props: {
    funnel: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      preview: null,
      offerDetailsDialog: false,
      baseUrl: process.env.VUE_APP_RJ_BASE_URL,
      imagesUrl: process.env.VUE_APP_RJ_OFFER_IMAGES_URL,
    };
  },

  computed: {
    offerImage() {
      return this.$store.getters['funnels/offerImage'];
    },

    shareUrl() {
      const fakeSurveyUrl = 'abc123';

      return `${process.env.VUE_APP_RJ_BASE_SHARE_URL}/${fakeSurveyUrl}`;
    },

    ogShareUrl() {
      // any string since we aren't sharing a real offer
      const fakeSurveyUrl = 'abc123';
      const query = httpBuildQuery({
        offerUrl: this.funnel.offerUrl,
        companyId: this.funnel.companyId,
        offerImage: this.funnel.offerImage || '',
        offerShareBody: this.funnel.offerShareBody,
        offerShareSubject: this.funnel.offerShareSubject,
      });

      return encodeURI(`${this.baseUrl}/og/${fakeSurveyUrl}?${query}`);
    },

    networks() {
      const { offerShareMethod1, offerShareMethod2, offerShareMethod3 } = this.funnel;

      return [offerShareMethod1, offerShareMethod2, offerShareMethod3].filter((nt) => nt !== null);
    },
  },

  watch: {
    offerImage: {
      handler: 'generatePreview',
      immediate: true,
    },
  },

  methods: {
    generatePreview(file) {
      if (file === null) {
        this.preview = `${this.imagesUrl}/${this.funnel.companyId}/${this.funnel.offerImage}`;
        return null;
      }

      if (typeof file === 'string') {
        this.preview = `${this.imagesUrl}/${this.funnel.companyId}/${file}`;
        return null;
      }

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.preview = reader.result;
      });
      reader.readAsDataURL(file);

      return null;
    },
  },
};
</script>
