var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',_vm._g({staticClass:"overflow-hidden"},_vm.$listeners),[_c('div',{attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',{staticClass:"p-6",class:{
        'flex justify-between flex-row-reverse items-center': _vm.iconRight,
        'text-center': !_vm.iconRight && _vm.hideChart,
        'pb-0': !_vm.hideChart
      }},[_c('div',{staticClass:"flex items-center justify-between",class:{'mb-4': !_vm.iconRight}},[_c('feather-icon',{staticClass:"p-3 inline-flex rounded-full",style:({
            color: _vm.themeColors[_vm.color],
            'background-color': _vm.Color(_vm.themeColors[_vm.color]).lightness(93).hex(),
          }),attrs:{"icon":_vm.icon}}),_c('div',[_vm._t("actions")],2)],1),_c('div',{staticClass:"truncate"},[_c('h2',{staticClass:"mb-1 font-bold"},[_vm._v(" "+_vm._s(_vm.statistic)+" ")]),_c('span',[_vm._v(_vm._s(_vm.statisticTitle))])])]),(!_vm.hideChart)?_c('div',{staticClass:"line-area-chart"},[_c('vue-apex-charts',{ref:"apexChart",attrs:{"type":_vm.type,"height":"100","width":"100%","options":_vm.chartOptions,"series":_vm.chartData}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }