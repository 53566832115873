<template>
  <div class="mb-4">
    <vx-card title="Review Sites">
      <template slot="actions">
        <vs-button
          class="mr-2"
          type="border"
          @click="threePackModal = true"
        >
          3-Pack
        </vs-button>

        <vs-button
          @click="toggleReviewSiteModal()"
        >
          Add Site
        </vs-button>
      </template>

      <template slot="no-body">
        <vs-table
          class="mt-6"
          :data="locationReviewSites"
          :no-data-text="'No Review Sites'"
          @selected="toggleReviewSiteModal"
        >
          <template slot="thead">
            <vs-th>
              <span class="uppercase w-full text-center">Review Site</span>
            </vs-th>
            <vs-th>
              <span class="uppercase w-full text-center">Reviews</span>
            </vs-th>
            <vs-th>
              <span class="uppercase w-full text-center">Rating</span>
            </vs-th>
            <vs-th>
              <span class="uppercase w-full text-center">Monitoring</span>
            </vs-th>
            <vs-th>
              <span class="uppercase w-full text-center">Action</span>
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              v-for="(site, index) in data"
              :key="index"
              :data="site"
            >
              <vs-td>
                <div class="flex items-center">
                  <img
                    :src="`${VUE_APP_RJ_REVIEW_SITE_LOGOS_URL}/${site.reviewSite.id}/${site.reviewSite.logo}`"
                    :alt="site.reviewSite.name"
                    class="mr-3 w-6"
                  >
                  <p class="font-medium">
                    {{ site.reviewSite.name || '' }}
                  </p>
                </div>
              </vs-td>
              <vs-td>
                <div class="w-full flex justify-center">
                  {{ site.reviewCount || 0 }}
                </div>
              </vs-td>
              <vs-td>
                <div class="w-full flex justify-center">
                  <star-rating
                    border-color="#d8d8d8"
                    :rating="(site.rating || 0) * 5"
                    :border-width="3"
                    :rounded-corners="true"
                    :star-size="14"
                    :show-rating="false"
                    :read-only="true"
                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                  />
                </div>
              </vs-td>
              <vs-td>
                <div class="w-full flex justify-center">
                  <feather-icon
                    svg-classes="w-5"
                    :icon="isSiteMonitored(site) ? 'CheckCircleIcon' : 'CircleIcon'"
                    :style="{
                      color: themeColors.rjPurple
                    }"
                  />
                </div>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <vs-button
                  v-if="!isDisabled"
                  icon-pack="feather"
                  icon="icon-settings"
                  type="flat"
                  class="text-rjPurple inline-block"
                  title="Edit Review Site"
                  @click.stop="toggleReviewSiteModal(site)"
                />
                <vs-button
                  v-if="site.profileUrl || site.newReviewUrl"
                  icon-pack="feather"
                  icon="icon-external-link"
                  type="flat"
                  class="text-rjPurple inline-block"
                  title="Open Review Site"
                  @click.stop="jumpToSitePage(site)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </vx-card>

    <rj-three-pack-modal
      v-if="threePackModal"
      :active.sync="threePackModal"
      :location-review-sites="locationReviewSites"
      @edit-three-pack="onEditThreePack"
    />

    <rj-review-site-dialog
      v-if="reviewSiteModal.active"
      :review-sites="reviewSites"
      :active.sync="reviewSiteModal.active"
      :location-review-site="reviewSiteModal.reviewSite"
      @review-site-updated="onEditReviewSite"
      @review-site-added="onAddReviewSite"
    />
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';
import Authorizable from '@/mixins/Authorizable';
import { colors as themeColors } from '@/../themeConfig';
import RjThreePackModal from '@/views/common/locations/modals/RjThreePack.vue';
import RjReviewSiteDialog from '@/views/common/locations/modals/RjReviewSiteDialog.vue';
import { MONITORED_REVIEW_SITES } from '@/rj_consts';

const { VUE_APP_RJ_REVIEW_SITE_LOGOS_URL } = process.env;

export default {
  name: 'RjReviewSite',

  components: {
    StarRating,
    RjThreePackModal,
    RjReviewSiteDialog,
  },

  mixins: [Authorizable],

  props: {
    location: {
      type: Object,
      required: true,
    },

    locationReviewSites: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      themeColors,
      VUE_APP_RJ_REVIEW_SITE_LOGOS_URL,
      threePackModal: false,
      reviewSiteModal: {
        active: false,
        reviewSite: {},
      },
    };
  },

  computed: {
    isDisabled() {
      return this.guard_is('Company', (role) => /^Company/.test(role));
    },

    reviewSites() {
      return this.$store.getters['revenuejump/reviewSites'];
    },
  },

  methods: {
    toggleReviewSiteModal(reviewSite = {}) {
      this.reviewSiteModal.reviewSite = reviewSite;
      this.reviewSiteModal.active = !this.reviewSiteModal.active;
    },

    jumpToSitePage(site) {
      window.open(site.profileUrl || site.newReviewUrl, '_blank', 'noopener');
    },

    async onEditThreePack(changes) {
      try {
        await this.$store.dispatch('locations/updateThreePack', changes);
        this.$vs.notify({ title: 'Success', text: '3-Pack saved.', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async onEditReviewSite(payload) {
      try {
        await this.$store.dispatch('locations/updateReviewSite', { ...payload, locationId: this.location.id });
        this.$vs.notify({ title: 'Success', text: 'Review Site saved.', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async onAddReviewSite(payload) {
      try {
        const locationId = this.location.id;
        await this.$store.dispatch('locations/addReviewSite', { locationId, payload });
        this.$emit('review-site-added');
        this.$vs.notify({ title: 'Success', text: 'Review Site added.', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    isSiteMonitored(locationReviewSite) {
      const { reviewSite: { code } } = locationReviewSite;
      const { gmbRefreshToken } = this.$store.getters['companies/company'];

      if (code === 'google') {
        return gmbRefreshToken;
      }

      const { facebookAccessToken } = this.$store.getters['locations/location'];

      if (code === 'facebook') {
        return facebookAccessToken;
      }

      return MONITORED_REVIEW_SITES.includes(code);
    },
  },
};
</script>
