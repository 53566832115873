<template>
  <div class="flex items-center h-10 w-full rounded-lg relative">
    <vs-button
      icon-pack="feather"
      size="small"
      icon="icon-minus"
      class="flex-shrink-0 p-1 absolute left-0 -ml-1 btn-less"
      :class="{ 'bg-gray-500 cursor-not-allowed': limit }"
      @click="decrement"
    />
    <span
      class="mx-2 select-none border border-gray-300 text-center flex items-center justify-center h-full w-full bg-gray-200 font-semibold text-md hover:text-black text-gray-700 rounded"
      v-text="amount"
    />
    <vs-button
      icon-pack="feather"
      size="small"
      icon="icon-plus"
      class="flex-shrink-0 p-1 absolute right-0 -mr-1"
      @click="increment"
    />
  </div>
</template>

<script>
export default {
  name: 'RjInputNumber',

  props: {
    value: {
      type: Number,
      required: true,
    },

    step: {
      type: Number,
      required: false,
      default: 1,
    },

    min: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      amount: this.min > this.value ? this.min : this.value,
    };
  },

  computed: {
    limit() {
      return this.min >= this.amount;
    },
  },

  watch: {
    amount: {
      handler: 'evnt',
    },
  },

  methods: {
    evnt(value) {
      this.$emit('input', value);
    },

    increment() {
      this.amount += this.step;
    },

    decrement() {
      if (this.limit) {
        this.amount = this.min;
        return;
      }

      this.amount -= this.step;
    },
  },
};
</script>
