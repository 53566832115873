<template>
  <vx-card title="Company Details">
    <form @submit.prevent="onSubmit">
      <!-- Company Name -->
      <vs-input
        v-model="form.name"
        label="Company Name"
        class="w-full"
        :disabled="isDisabled"
      />

      <!-- Category -->
      <div class="mt-3">
        <label class="vs-input--label">Category</label>
        <rj-select
          v-model="form.categoryId"
          label="name"
          :options="categories"
          :reduce="(category) => category.id"
          placeholder="Category"
        />
      </div>

      <!-- Phone Number -->
      <vs-input
        v-model="form.phone"
        label="Phone"
        class="w-full mt-3"
      />

      <!-- Country & Region -->
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mt-3">
          <label class="vs-input--label">Country</label>
          <rj-select
            v-model="form.addressCountry"
            label="countryName"
            :options="countries"
            :reduce="(country) => country.countryShortCode"
            :clearable="false"
            class="w-full"
          />
        </div>

        <div class="vx-col sm:w-1/2 w-full mt-3">
          <label class="vs-input--label">State/Province/Region</label>
          <rj-select
            v-model="form.addressRegion"
            label="name"
            :options="regions"
            :reduce="(region) => region.shortCode"
            :clearable="false"
            class="w-full"
          />
        </div>
      </div>

      <!-- Street Address -->
      <vs-input
        v-model="form.addressStreet"
        class="w-full mt-3"
        label="Street Address"
      />

      <!-- City & Zip -->
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mt-3">
          <vs-input
            v-model="form.addressCity"
            class="w-full"
            label="City"
          />
        </div>

        <div class="vx-col sm:w-1/2 w-full mt-3">
          <vs-input
            v-model="form.addressZip"
            label="Zip/Postal Code"
            class="w-full"
          />
        </div>
      </div>

      <!-- Website -->
      <vs-input
        v-model="form.website"
        label="Website"
        class="w-full mt-3"
      />

      <template v-if="!pristine">
        <div class="flex flex-wrap items-center justify-end mt-6">
          <vs-button button="submit">
            Save
          </vs-button>
        </div>
      </template>
    </form>
  </vx-card>
</template>

<script>
import _debounce from 'lodash/debounce';
import Authorizable from '@/mixins/Authorizable';
import { hasKey, isPristine } from '@/utils';

export default {
  name: 'RjCompanyForm',

  mixins: [Authorizable],

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pristine: true,
      old: {},
      form: {
        addressCountry: 'US',
        addressRegion: '',
        addressStreet: '',
        addressCity: '',
        addressZip: '',
        categoryId: '',
        mainContactId: '',
        name: '',
        otherCategory: '',
        phone: '',
        website: '',
      },
    };
  },

  computed: {
    isDisabled() {
      return this.guard_is('Company', (role) => /^Company/.test(role));
    },

    countries() {
      return this.$store.getters['geography/countries'];
    },

    categories() {
      return this.$store.getters['revenuejump/categories'];
    },

    regions() {
      return this.countries.find((c) => c.countryShortCode === this.form.addressCountry).regions;
    },
  },

  watch: {
    'form': {
      handler: 'hasChanges',
      deep: true,
    },

    'form.addressCountry': {
      handler: 'setOldRegion',
    },
  },

  mounted() {
    this.hydrateForm();
  },

  methods: {
    async onSubmit() {
      try {
        await this.$store.dispatch('companies/updateCompanyDetails', {
          payload: this.form,
          companyId: this.company.id,
        });

        this.$vs.notify({ title: 'Success', text: 'Company saved.', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }

      this.old = { ...this.form };
    },

    hydrateForm() {
      for (const key in this.company) {
        if (hasKey(this.company, key) && hasKey(this.form, key)) {
          this.form[key] = this.company[key] || '';
        }
      }
      this.old = { ...this.form };
    },

    setOldRegion(countryCode) {
      if (countryCode === this.old.addressCountry) {
        this.form.addressRegion = this.old.addressRegion;
      } else {
        this.form.addressRegion = this.regions[0].code;
      }
    },

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(original) {
      this.pristine = isPristine(original, this.old);
    }, 150),
  },
};
</script>
