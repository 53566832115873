import { httpBuildQuery, bus } from '@/utils';
import Authorizable from '@/mixins/Authorizable';

export default {
  mixins: [Authorizable],

  computed: {
    hasReachedLimit() {
      return !this.guard_company_plan_allows('SURVEY_TEMPLATES', this.funnels.length);
    },
  },

  methods: {
    /**
     * Fetch the list of funnels for the given company
     *
     * @return {Promise}
     */
    async fetch(companyId) {
      const filters = httpBuildQuery({
        includeSurveyStats: 1,
        filter: JSON.stringify({
          where: { companyId },
        }),
      });

      await this.$store.dispatch('funnels/fetchFunnels', filters);
    },

    /**
     * Create a copy of the given funnel
     *
     * @param  {Object} funnel
     * @return {Promise}
     */
    async copy(funnel) {
      const { id, name, ...rest } = funnel;

      if (this.hasReachedLimit && this.isLowJump) {
        bus.$emit('upgrade-company-intent', this.company);
        return;
      }

      try {
        await this.$store.dispatch('funnels/createFunnel', {
          companyId: funnel.companyId,
          payload: { ...rest, name: 'Funnel Copy' },
        });

        this.$vs.notify({ title: 'Success', text: 'Funnel copied.', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: 'Funnels limit reached.', color: 'danger' });
      }
    },

    /**
     * Confirmation dialog before actually removing the funnel
     *
     * @param  {Object} funnel
     * @return {void}
     */
    removeConfirmation(funnel) {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'You are about to delete this funnel and this action cannot be undone.',
        acceptText: 'Delete',
        accept: () => this.remove(funnel),
      });
    },

    /**
     * Remove the funnel from the list
     *
     * @param  {Object} funnel
     * @return {Promise}
     */
    async remove(funnel) {
      try {
        await this.$store.dispatch('funnels/deleteFunnel', funnel.id);
        this.$vs.notify({ title: 'Success', text: 'Funnel deleted.', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: 'Error while deleting Funnel.', color: 'danger' });
      }
    },
  },
};
