<template>
  <vx-card title="Logo">
    <rj-company-logo-uploader
      :logo="baseLogoUrl"
      @file-uploaded="onLogoUpload"
    />
  </vx-card>
</template>

<script>
import RjCompanyLogoUploader from '@/components/RjCompanyLogoUploader.vue';

export default {
  name: 'RjCompanyLogo',

  components: {
    RjCompanyLogoUploader,
  },

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      preview: null,
    };
  },

  computed: {
    baseLogoUrl() {
      if (this.preview !== null) {
        return this.preview;
      }

      const { id, logo } = this.company;
      if (logo && /https?:\/\//.test(logo)) {
        return logo;
      }
      if (logo) {
        return `${process.env.VUE_APP_RJ_COMPANY_LOGO_URL}/${id}/${logo}`;
      }
      return null;
    },
  },

  methods: {
    onLogoUpload(payload) {
      if (!payload) {
        return;
      }

      this.preview = payload.fileData;
      this.upload(payload.file, this.company.id);
    },

    async upload(file, companyId) {
      try {
        await this.$store.dispatch('companies/updateCompanyLogo', { file, companyId });
        this.$vs.notify({ title: 'Success', text: 'Logo successfully uploaded.', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },
  },
};
</script>
