<template>
  <vs-prompt
    :title="title"
    :active="active"
    :buttons-hidden="pristine"
    :accept-text="acceptBtnText"
    :style="{ zIndex: 51002 }"
    class="rj-confirm-modal"
    @accept="submit"
    @close="close"
  >
    <vs-tabs alignment="fixed">
      <form>
        <vs-tab label="Details">
          <div class="vx-row mt-3">
            <!-- First Name -->
            <div class="vx-col w-full sm:w-1/2">
              <vs-input
                v-model="form.firstName"
                v-validate="'required'"
                name="First Name"
                label="First Name"
                class="w-full"
              />
              <span class="text-sm text-red-500">
                {{ errors.first('First Name') }}
              </span>
            </div>

            <!-- Last Name -->
            <div class="vx-col w-full sm:w-1/2">
              <vs-input
                v-model="form.lastName"
                v-validate="'required'"
                name="Last Name"
                label="Last Name"
                class="w-full"
              />
              <span class="text-sm text-red-500">
                {{ errors.first('Last Name') }}
              </span>
            </div>
          </div>

          <!-- Role -->
          <div
            class="mt-3"
            @click="inputClicked('role')"
          >
            <label class="vs-input--label">Role</label>
            <rj-select
              v-model="form.roleId"
              v-validate="'required'"
              name="Role"
              :options="roles"
              :reduce="(role) => role.id"
              :clearable="false"
              :disabled="context === 'company' && isLowJump"
              placeholder="Select a role..."
            />
            <span class="text-sm text-red-500">
              {{ errors.first('Role') }}
            </span>
          </div>

          <!-- Email Address -->
          <div class="mt-3">
            <vs-input
              v-model="form.email"
              v-validate="'required'"
              name="Email"
              class="w-full mt-3"
              label="Email Address"
            />
            <span class="text-sm text-red-500">
              {{ errors.first('Email') }}
            </span>
          </div>

          <template v-if="mode === 'edit'">
            <div class="mt-3">
              <vs-button
                class="w-full mt-3"
                @click="resetPassword"
              >
                Reset Password
              </vs-button>
              <p
                v-if="resetPasswordEmailSent"
                class="text-green-500 py-2"
              >
                An email has been sent to {{ user.firstName }} with instructions
                for resetting their password.
              </p>
            </div>
          </template>
        </vs-tab>

        <template v-if="context === 'company'">
          <vs-tab label="Notifications">
            <ul
              v-if="notificationRoles.length"
              class="space-y-2"
            >
              <li
                v-for="(notification, index) in notificationRoles"
                :key="index"
              >
                <vs-checkbox v-model="form[notification.value]">
                  {{ notification.label }}
                </vs-checkbox>
              </li>
            </ul>

            <div
              v-else
              class="p-4"
            >
              <p v-if="selectedRole">
                {{ selectedRole.label }} role does not receive notifications.
              </p>
              <p v-else>
                Select a Role to see available notifications.
              </p>
            </div>
          </vs-tab>

          <vs-tab
            v-if="locations.length > 1"
            label="Location Access"
          >
            <ul>
              <li
                v-for="location in form.locationAccessControl"
                :key="location.id"
              >
                <vs-checkbox
                  v-model="location.hasAccess"
                  :disabled="roleHasFullLocationAccess"
                >
                  {{ location.name }}
                </vs-checkbox>
              </li>
            </ul>
          </vs-tab>
        </template>
      </form>
    </vs-tabs>
  </vs-prompt>
</template>

<script>
import _debounce from 'lodash/debounce';
import _cloneDeep from 'lodash/cloneDeep';
import { isPristine, hasKey } from '@/utils';
import Authorizable from '@/mixins/Authorizable';

export default {
  name: 'RjUserModal',

  mixins: [Authorizable],

  props: {
    active: {
      type: Boolean,
      requried: true,
    },

    partnerId: {
      type: Number,
      required: false,
      default: null,
    },

    company: {
      type: Object,
      required: false,
      default: () => {},
    },

    context: {
      type: String,
      required: true,
      validator: (value) => ['company', 'partner', 'admin'].includes(value),
    },

    user: {
      type: [Object, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      form: {
        id: null,
        adminId: null,
        companyId: null,
        partnerId: null,
        email: '',
        firstName: '',
        lastName: '',
        roleId: '',
        locationAccessControl: [],
        receiveBillingNotifications: true,
        receiveNegativeFeedbackNotifications: true,
        receiveNegativeReviewNotifications: true,
        receiveSurveyReplyNotifications: true,
        receiveTestimonialNotifications: true,
        receiveWeeklyRecap: true,
      },
      old: {},
      pristine: true,
      resetPasswordEmailSent: false,
    };
  },

  computed: {
    acceptBtnText() {
      return this.mode === 'create' ? 'Create' : 'Save';
    },

    locations() {
      return this.$store.getters['locations/locations'];
    },

    mode() {
      return (this.user !== null) ? 'edit' : 'create';
    },

    notificationRoles() {
      if (!this.form.roleId || this.form.roleId < 0) {
        return [];
      }

      const roleName = this.roles.find((r) => r.id === this.form.roleId).name;
      return this.$store.getters['roles/notificationRoles'][roleName];
    },

    roles() {
      const roles = this.$store.getters[`roles/${this.context}Roles`].map((role) => ({
        id: role.id,
        name: role.name,
        label: role.description,
      }));

      return [...roles].sort((curr, next) => (curr.name < next.name ? -1 : 1) || 0);
    },

    selectedRole() {
      return this.roles.find((r) => r.id === this.form.roleId);
    },

    roleHasFullLocationAccess() {
      if (!this.selectedRole) {
        return false;
      }
      return ['CompanyOwner', 'CompanyAdmin'].includes(this.selectedRole.name);
    },

    title() {
      return this.mode === 'create' ? 'Add User' : 'Edit User';
    },
  },

  watch: {
    'form': {
      handler: 'hasChanges',
      deep: true,
    },

    'context': {
      handler: 'setContextFormKeys',
      immediate: true,
    },

    'form.roleId': {
      handler: 'setNotificationsDefaults',
      immediate: true,
    },
  },

  created() {
    this.setLocationAccessDefaults();
    if (this.user === null) {
      this.buildEmptyFormObject();
    } else {
      this.buildFormObject(this.user);
    }
  },

  methods: {
    /**
     * Decides which action the modal should take.
     *
     * @return {void}
     */
    async submit() {
      const result = this.$validator.validateAll();
      if (!result) {
        return;
      }

      this.mode === 'create' ? this.create() : this.update();
    },

    /**
     * Create a new User.
     *
     * @return {void}
     */
    async create() {
      const { id, ...payload } = this.form;
      this.$emit('create-user', payload);
    },

    /**
     * Updates the current User.
     *
     * @return {void}
     */
    async update() {
      this.$emit('update-user', this.form);
    },

    async resetPassword() {
      const { result } = await this.$store.dispatch('users/resetPassword', this.form.email);
      if (result) {
        this.resetPasswordEmailSent = true;
      }
    },

    buildEmptyFormObject() {
      this.old = _cloneDeep(this.form);
    },

    /**
     * Sets the form values with the given user data
     *
     * @param  {Object} user
     * @return {void}
     */
    buildFormObject(user) {
      for (const key in user) {
        if (hasKey(user, key) && hasKey(this.form, key)) {
          this.form[key] = user[key];
        }
      }

      this.form.roleId = this.user.role.id;
      this.form.locationAccessControl = [...this.hasAccessToLocations(this.locations)];

      this.old = _cloneDeep(this.form);
    },

    /**
     * Sets the notifications default values for the current selected role
     */
    setNotificationsDefaults(roleId) {
      if (this.user && this.user.role.id === roleId) {
        return;
      }

      const notifications = this.notificationRoles.map((nt) => nt.value);

      for (const key in this.form) {
        if (hasKey(this.form, key) && key.startsWith('receive')) {
          this.form[key] = notifications.some((nt) => nt === key);
        }
      }
    },

    /**
     * Sets the location access control default values
     */
    setLocationAccessDefaults() {
      this.form.locationAccessControl = this.locations.map((l) => ({
        hasAccess: true,
        id: l.id,
        name: l.name,
      }));
    },

    /**
     * Checks which locations the user has access to
     *
     * @param  {Array} locations
     * @return {Array}
     */
    hasAccessToLocations(locations) {
      // if location is found in locationAccessControl, user
      // does not have access to that location
      return locations.map((lc) => ({
        id: lc.id,
        name: lc.name,
        hasAccess: !this.form.locationAccessControl.some((ac) => ac.locationId === lc.id),
      }));
    },

    /**
     * Sets the companyId or partnerId values according to the current modal context
     *
     * @param {String} context
     */
    setContextFormKeys(context) {
      if (this.user !== null) {
        return;
      }

      if (context === 'company') {
        this.form.companyId = this.company.id;
      }

      if (context === 'partner') {
        this.form.partnerId = this.partnerId;
      }
    },

    /**
     * Checks if the form has been changed.
     *
     * @param  {Object} original
     * @return {void}
     */
    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(current) {
      const { locationAccessControl: currentAccess, ...form } = current;
      const { locationAccessControl: oldAccess, ...old } = this.old;

      const sameLocations = currentAccess.every((lc, index) => lc.hasAccess === oldAccess[index].hasAccess);

      this.pristine = isPristine(form, old) && sameLocations;
    }, 150),

    inputClicked() {
      if (this.isLowJump && this.context === 'company') {
        this.$emit('should-upgrade');
      }
    },

    /**
     * Programatic way of closing the modal.
     *
     * @return {void}
     */
    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
