<template>
  <!-- Notifications -->
  <vs-dropdown
    ref="notificationDropDown"
    vs-custom-content
    vs-trigger-click
    class="cursor-pointer"
  >
    <feather-icon
      icon="BellIcon"
      class="cursor-pointer p-2"
      :badge="unreadNotificationsCount"
      :style="{
        color: themeColors['primary']
      }"
    />
    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">
      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">
          {{ unreadNotificationsCount }} New
        </h3>
        <p class="opacity-75">
          App Notifications
        </p>
      </div>

      <component
        :is="scrollbarTag"
        ref="mainSidebarPs"
        :key="$vs.rtl"
        class="scroll-area--nofications-dropdown p-0 mb-10"
        :settings="settings"
      >
        <ul class="bordered-items">
          <li
            v-for="notification in unreadSystemNotifications"
            :key="notification.index"
            class="flex justify-between px-4 py-4 notification cursor-pointer"
            @click="goToItem(notification)"
          >
            <div class="flex items-start">
              <feather-icon
                :icon="notification.icon"
                :svg-classes="[`text-${notification.category}`, 'stroke-current mr-1 h-6 w-6']"
              />
              <div class="mx-2">
                <span
                  class="font-medium block notification-title"
                  :class="[`text-${notification.category}`]"
                >
                  {{ notification.title }}
                </span>
                <small>
                  {{ notification.msg }}
                </small>
              </div>
            </div>
            <div class="flex flex-col">
              <small class="mt-1 whitespace-no-wrap">
                {{ elapsedTime(notification.time) }}
              </small>
              <vs-button
                icon-pack="feather"
                icon="icon-check"
                type="flat"
                class="inline-block"
                title="Mark as Read"
                :color="themeColors.rjPurple"
                @click.stop="markAsRead(notification)"
              />
            </div>
          </li>
          <li
            v-for="notification in unreadNotifications"
            :key="notification.index"
            class="flex justify-between px-4 py-4 notification cursor-pointer"
            @click="goToItem(notification)"
          >
            <div class="flex items-start">
              <feather-icon
                :icon="notification.icon"
                :svg-classes="[`text-${notification.category}`, 'stroke-current mr-1 h-6 w-6']"
              />
              <div class="mx-2">
                <span
                  class="font-medium block notification-title"
                  :class="[`text-${notification.category}`]"
                >
                  {{ notification.title }}
                </span>
                <small>
                  {{ notification.msg }}
                </small>
              </div>
            </div>
            <div class="flex flex-col">
              <small class="mt-1 whitespace-no-wrap">
                {{ elapsedTime(notification.time) }}
              </small>
              <vs-button
                icon-pack="feather"
                icon="icon-check"
                type="flat"
                class="inline-block"
                title="Mark as Read"
                :color="themeColors.rjPurple"
                @click.stop="markAsRead(notification)"
              />
            </div>
          </li>
        </ul>
      </component>
      <div
        class="
        checkout-footer
        fixed
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer"
      >
        <button @click="viewAllNotifications">
          View All Notifications
        </button>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { colors as themeColors } from '@/../themeConfig';

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      themeColors,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.60,
      },
      refreshIntervalId: null,
    };
  },
  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    unreadNotifications() {
      return this.$store.getters['notifications/unreadNotifications'];
    },
    unreadSystemNotifications() {
      return this.$store.getters['notifications/unreadSystemNotifications'];
    },
    unreadNotificationsCount() {
      return this.$store.getters['notifications/unreadNotificationsCount'];
    },
  },
  mounted() {
    // periodically fetch notifications for partner and company users
    if (this.auth.user.companyId || this.auth.user.partnerId) {
      this.refreshIntervalId = setInterval(this.fetchTop5Notifications, 1000 * 30);
    }
  },
  beforeDestroy() {
    clearInterval(this.refreshIntervalId);
  },
  methods: {
    fetchTop5Notifications() {
      this.$store.dispatch('notifications/getTop5Notifications');
    },
    elapsedTime(startTime) {
      const x = new Date(startTime);
      const now = new Date();
      let timeDiff = now - x;
      timeDiff /= 1000;

      const seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      const minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      const hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      const days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      const years = timeDiff;

      if (years > 0) {
        return `${years + (years > 1 ? ' Years ' : ' Year ')}ago`;
      } if (days > 0) {
        return `${days + (days > 1 ? ' Days ' : ' Day ')}ago`;
      } if (hours > 0) {
        return `${hours + (hours > 1 ? ' Hrs ' : ' Hour ')}ago`;
      } if (minutes > 0) {
        return `${minutes + (minutes > 1 ? ' Mins ' : ' Min ')}ago`;
      } if (seconds > 0) {
        return seconds + (seconds > 1 ? ' sec ago' : 'just now');
      }

      return 'Just Now';
    },
    async markAsRead(notification) {
      try {
        if (notification.viewedAt) {
          return;
        }
        const viewedAt = new Date().toISOString();
        const payload = {
          id: notification.id,
          viewedAt,
        };

        await this.$store.dispatch('notifications/updateNotification', payload);
        this.$store.dispatch('notifications/getTop5Notifications');
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },
    goToItem(notification) {
      const { type } = notification;
      if (type === 'New Review') {
        this.$router.push({ name: 'company-reviews' });
        document.querySelector('body').click();
        return;
      }
      if (type === 'New Testimonial') {
        this.$router.push({ name: 'company-testimonials' });
        document.querySelector('body').click();
        return;
      }
      if (type === 'Negative Feedback') {
        this.$router.push({ name: 'company-negative-feedback' });
        document.querySelector('body').click();
      }
      if (type === 'SMS Usage') {
        this.$router.push({ name: 'company-notifications' });
        document.querySelector('body').click();
      }
    },
    viewAllNotifications() {
      if (this.auth.user.companyId) {
        this.$router.push({ name: 'company-notifications' });
        document.querySelector('body').click();
      }
      if (this.auth.user.partnerId) {
        this.$router.push({ name: 'partner-notifications' });
        document.querySelector('body').click();
      }
    },
  },
};

</script>
