
const defaultFunnel = (mergeFields) => ({
  name: 'New Funnel',
  type: 'Testimonial',
  default: true,
  filterNegativeSentiment: true,
  reviewSite1Id: 2,
  reviewSite2Id: 1,
  reviewSite3Id: 3,
  message1PreferredType: 'Text',
  message1Delay: 0,
  message1EmailFromEmail: '',
  message1EmailFromName: '',
  message1EmailSubject: 'Thank you very much!',
  message1EmailBody: `Hey <FirstName>,\n\nThanks for choosing ${mergeFields.companyName}! Will you do us a quick favor and give us your feedback? Just click here:\n\n<SurveyLink>`,
  message1TextBody: `Hey <FirstName>. Thanks for choosing ${mergeFields.companyName}! Will you do us a quick favor and give us your feedback? Tap here: <SurveyLink>`,
  message2PreferredType: 'Email',
  message2Delay: 86400,
  message2EmailFromEmail: '',
  message2EmailFromName: '',
  message2EmailSubject: 'Thank you very much!',
  message2EmailBody: `Hey <FirstName>,\n\nThanks for choosing ${mergeFields.companyName}! Will you do us a quick favor and give us your feedback? Just click here:\n\n<SurveyLink>`,
  message2TextBody: `Hey <FirstName>. Thanks for choosing ${mergeFields.companyName}! Will you do us a quick favor and give us your feedback? Tap here: <SurveyLink>`,
  message3PreferredType: 'TextEmail',
  message3Delay: 604800,
  message3EmailFromEmail: '',
  message3EmailFromName: '',
  message3EmailSubject: 'Thank you very much!',
  message3EmailBody: `Hey <FirstName>,\n\nThanks for choosing ${mergeFields.companyName}! Will you do us a quick favor and give us your feedback? Just click here:\n\n<SurveyLink>`,
  message3TextBody: `Hey <FirstName>. Thanks for choosing ${mergeFields.companyName}! Will you do us a quick favor and give us your feedback? Tap here: <SurveyLink>`,
  surveyQuestionText: `Did ${mergeFields.companyName} meet your expectations?`,
  surveyQuestionYesResponse: 'Thanks for the feedback! Will you please help our business and share your experience online?',
  surveyQuestionNoResponse: 'Please help us improve! What could we have done differently?',
  offerTitle: '',
  offerBody: '',
  offerDetails: '',
  offerImage: null,
  offerShareSubject: '',
  offerShareBody: '',
  offerUrl: '',
  photoRequestHeading: 'Please add your photo!',
  photoRequestContent: 'Will you help our business by uploading a photo that we can place next to your kind words on our website? Thank you!',
  reviewRequestHeading: 'Thanks for the feedback!',
  reviewRequestContent: 'It would mean a lot to us if you left us an online review. Please click below:',
  offerShareMethod1: 'Facebook',
  offerShareMethod2: 'SMS',
  offerShareMethod3: 'Email',
  active: false,
  expiring: false,
  allowDuplicatesAfter: 0,
  replyToEmailAddress: '',
});

export default defaultFunnel;
