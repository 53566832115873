<template>
  <div class="flex flex-wrap -mx-4 mb-2">
    <!-- Satisfaction Score -->
    <div class="w-full sm:w-1/2 lg:w-1/4 px-4 mb-4">
      <statistics-card-line
        icon="SmileIcon"
        :statistic="`${funnel.satisfactionScore}%`"
        statistic-title="Satisfaction Score"
        :chart-data="chartData"
        type="area"
        color="rjBlue"
      />
    </div>

    <!-- Visit Rate -->
    <div class="w-full sm:w-1/2 lg:w-1/4 px-4 mb-4">
      <statistics-card-line
        icon="EyeIcon"
        :statistic="`${funnel.visitRate}%`"
        statistic-title="Visit Rate"
        :chart-data="chartData"
        type="area"
        color="rjOrange"
      />
    </div>

    <!-- Competitor Review  -->
    <div class="w-full sm:w-1/2 lg:w-1/4 px-4 mb-4">
      <statistics-card-line
        icon="CheckCircleIcon"
        :statistic="`${funnel.completionRate}%`"
        statistic-title="Completion Rate"
        :chart-data="chartData"
        type="area"
        color="rjGreen"
      />
    </div>

    <!-- 30-Day Surveys -->
    <div class="w-full sm:w-1/2 lg:w-1/4 px-4 mb-4">
      <statistics-card-line
        icon="FileTextIcon"
        :statistic="funnel.thirtyDayCount"
        statistic-title="30-Day Surveys"
        :chart-data="chartData"
        type="area"
        color="rjRed"
      />
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';

export default {
  name: 'RjFunnelStatistics',

  components: { StatisticsCardLine },

  data() {
    return {
      chartData: [{ name: 'series-1', data: [] }],
    };
  },

  computed: {
    funnel() {
      return this.$store.getters['funnels/funnel'];
    },
  },
};
</script>
