<template>
  <vs-prompt
    title="Upgrade SMS Credits"
    :active="active"
    :buttons-hidden="pristine"
    :accept-text="acceptText"
    class="rj-confirm-modal"
    @accept="submit"
    @close="close"
  >
    <p>How many SMS credits do you want?</p>

    <div class="flex my-2">
      <div class="w-32">
        <rj-input-number
          v-if="loaded"
          v-model.number="amount"
          :step="extra500SmsPackSize"
          :min="minimum"
        />
      </div>
    </div>

    <p
      class="text-sm"
    >
      <span v-if="amount > current">You will be charged a prorated amount of {{ formattedProrated }} now and an extra {{ formattedCost }} USD monthly
        for this account beginning on the next {{ renewalDate }} of the month.</span>
      <span v-if="amount < current">Available SMS credits will be reduced immediately and your future monthly invoices will be reduced by {{ formattedCost }} USD.</span>
    </p>
  </vs-prompt>
</template>

<script>
import { format } from 'date-fns';
import RjInputNumber from '@/components/RjInputNumber.vue';
import { EXTRA_500_SMS_CODE, EXTRA_500_SMS_PACK_SIZE } from '@/rj_consts';
import { httpBuildQuery } from '@/utils';

export default {
  name: 'RjSmsPackModal',

  components: { RjInputNumber },

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    counts: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      amount: 0,
      proratedUnitAmount: 0,
      renewalDate: '',
      unitPrice: 0,
      submitting: false,
      maxPerLocation: 500,
      extra500SmsPackSize: EXTRA_500_SMS_PACK_SIZE,
      loaded: false,
    };
  },

  computed: {
    cost() {
      if (this.amount < this.counts.sms.max) {
        return ((this.counts.sms.max - this.amount) * this.unitPrice) / this.extra500SmsPackSize;
      }

      return ((this.amount - this.counts.sms.max) * this.unitPrice) / this.extra500SmsPackSize;
    },

    formattedCost() {
      return this.formatCentsAsDollars(this.cost);
    },

    formattedProrated() {
      let amount = this.proratedUnitAmount * (this.amount - this.current) * (1 / this.extra500SmsPackSize);
      // minimum $5.00
      if (amount > 0 && amount < 500) {
        amount = 500;
      }
      return this.formatCentsAsDollars(amount);
    },

    pristine() {
      return this.amount === this.counts.sms.max;
    },

    current() {
      return this.counts.locations * this.maxPerLocation + this.counts.smsAddOns * this.extra500SmsPackSize;
    },

    minimum() {
      return this.counts.locations * this.extra500SmsPackSize;
    },

    acceptText() {
      if (this.submitting) {
        return 'Processing...';
      }
      return 'Update';
    },
  },

  async mounted() {
    this.amount = this.counts.locations * this.extra500SmsPackSize + this.counts.smsAddOns * this.extra500SmsPackSize;
    const { unitPrice, amount, renewalDate } = await this.$store.dispatch('partners/getProratedAmountForAddOn', EXTRA_500_SMS_CODE);
    this.renewalDate = format(renewalDate, 'do');
    this.proratedUnitAmount = amount;
    this.unitPrice = unitPrice;
    this.loaded = true;
  },

  methods: {
    async submit() {
      if (this.submitting) {
        return;
      }
      if (this.amount > this.current) {
        await this.increaseQuantity();
      } else if (this.amount < this.current) {
        await this.decreaseQuantity();
      }
      this.close();
    },

    async increaseQuantity() {
      const companyId = this.$store.getters['companies/company'].id;
      const payload = {
        code: EXTRA_500_SMS_CODE,
        quantity: (this.amount - this.current) / 500,
        companyId,
      };
      this.submitting = true;
      const { error, errorMessage } = await this.$store.dispatch('partners/addAddOn', payload);
      if (error) {
        this.$vs.notify({ title: 'Error', text: errorMessage || error, color: 'danger' });
      } else {
        this.$vs.notify({ title: 'Success', text: 'Extra SMS purchased.', color: 'success' });
        const filters = httpBuildQuery({
          filter: JSON.stringify({
            include: [
              'plan',
              {
                relation: 'companyPlans',
                scope: {
                  where: {
                    active: true,
                  },
                  include: ['plan'],
                },
              },
              {
                relation: 'locations',
                scope: { where: { pending: false } },
              },
            ],
          }),
        });
        await this.$store.dispatch('companies/getCompany', { companyId, filters });
        await this.$store.dispatch('companies/fetchSmsStatus', companyId);
      }
    },

    async decreaseQuantity() {
      const companyId = this.$store.getters['companies/company'].id;
      const payload = {
        code: EXTRA_500_SMS_CODE,
        quantity: (this.current - this.amount) / 500,
        companyId,
      };
      this.submitting = true;
      const { error, errorMessage } = await this.$store.dispatch('partners/removeAddOn', payload);
      if (error) {
        this.$vs.notify({ title: 'Error', text: errorMessage || error, color: 'danger' });
      } else {
        this.$vs.notify({ title: 'Success', text: 'Extra SMS removed.', color: 'success' });
        const filters = httpBuildQuery({
          filter: JSON.stringify({
            include: [
              'plan',
              {
                relation: 'companyPlans',
                scope: {
                  where: {
                    active: true,
                  },
                  include: ['plan'],
                },
              },
              {
                relation: 'locations',
                scope: { where: { pending: false } },
              },
            ],
          }),
        });
        await this.$store.dispatch('companies/getCompany', { companyId, filters });
        await this.$store.dispatch('companies/fetchSmsStatus', companyId);
      }
    },

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
