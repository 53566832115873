<template>
  <div class="vx-row">
    <div class="vx-col w-full sm:w-7/12">
      <!-- Survey Question -->
      <vs-input
        :value="form.surveyQuestionText"
        label="Survey Question"
        class="w-full"
        :disabled="isCompanyUser"
        @input="(value) => setFormValue('surveyQuestionText', value)"
      />

      <!-- Survey No Response -->
      <template v-if="funnel.filterNegativeSentiment !== false">
        <h3 class="mt-4 text-base">
          Response: No
        </h3>
        <vs-input
          :value="form.surveyQuestionNoResponse"
          label="Feedback Request"
          class="w-full mt-1"
          :disabled="isCompanyUser"
          @input="(value) => setFormValue('surveyQuestionNoResponse', value)"
        />
      </template>

      <!-- Survey Referral Fields -->
      <template v-if="isReferral">
        <h3 class="mt-4 text-base">
          Response: Yes
        </h3>
        <p class="text-gray-600 mt-1 leading-tight">
          This is the referral offer that the customer will see after completing the survey.
          Strong offers mean more referrals!
        </p>

        <!-- Heading -->
        <vs-input
          :value="form.offerTitle"
          label="Referral Offer Heading"
          class="w-full mt-2"
          :disabled="isCompanyUser"
          @input="(value) => setFormValue('offerTitle', value)"
        />

        <div class="vx-row mt-3">
          <div class="vx-col w-full sm:w-1/2">
            <!-- Body -->
            <label class="vs-input--label block">Referral Offer Content</label>
            <vs-textarea
              :value="form.offerBody"
              height="6rem"
              class="w-full p-3 mb-0"
              :disabled="isCompanyUser"
              :class="{ 'vs--disabled': isCompanyUser }"
              @input="(value) => setFormValue('offerBody', value)"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2">
            <!-- Offer Details -->
            <label class="vs-input--label block">Offer Details</label>
            <vs-textarea
              :value="form.offerDetails"
              height="6rem"
              class="w-full p-3 mb-0"
              :disabled="isCompanyUser"
              :class="{ 'vs--disabled': isCompanyUser }"
              @input="(value) => setFormValue('offerDetails', value)"
            />
          </div>
        </div>

        <!-- Share Methods -->
        <div class="mt-3">
          <label class="vs-input--label block">Share Methods</label>

          <rj-select
            v-model="form.offerShareMethod1"
            label="name"
            :options="availableShareMethods"
            :reduce="(method) => method.name"
            :clearable="true"
            :disabled="isCompanyUser"
            placeholder="Choose a share method..."
            class="mb-2"
            @input="(value) => setFormValue('offerShareMethod1', value)"
          />

          <rj-select
            v-model="form.offerShareMethod2"
            label="name"
            :options="availableShareMethods"
            :reduce="(method) => method.name"
            :clearable="true"
            :disabled="isCompanyUser"
            placeholder="Choose a share method..."
            class="mb-2"
            @input="(value) => setFormValue('offerShareMethod2', value)"
          />

          <rj-select
            v-model="form.offerShareMethod3"
            label="name"
            :options="availableShareMethods"
            :reduce="(method) => method.name"
            :clearable="true"
            :disabled="isCompanyUser"
            placeholder="Choose a share method..."
            @input="(value) => setFormValue('offerShareMethod3', value)"
          />
        </div>

        <h3 class="mt-4 text-base">
          Share Content
        </h3>
        <p class="text-gray-600 mt-1 leading-tight">
          This is is the content that will be shared by the customer with their friends.
        </p>

        <!-- Subject -->
        <vs-input
          :value="form.offerShareSubject"
          label="Subject"
          class="w-full mt-3"
          :disabled="isCompanyUser"
          @input="(value) => setFormValue('offerShareSubject', value)"
        />

        <!-- Body -->
        <div class="mt-3">
          <label class="vs-input--label block">Body</label>
          <vs-textarea
            :value="form.offerShareBody"
            height="6rem"
            class="w-full p-3 mb-0"
            :disabled="isCompanyUser"
            :class="{ 'vs--disabled': isCompanyUser }"
            @input="(value) => setFormValue('offerShareBody', value)"
          />
        </div>

        <!-- Landing Page Url -->
        <vs-input
          :value="form.offerUrl"
          label="Landing Page Url"
          class="w-full mt-3"
          :disabled="isCompanyUser"
          @input="(value) => setFormValue('offerUrl', value)"
        />

        <label class="vs-input--label block mt-3">Offer Image</label>
        <rj-offer-image-uploader
          :logo="offerImageSrc"
          @file-uploaded="onSelectOfferImage"
        />
      </template>

      <!-- Survey Testimonial Fields -->
      <template v-if="isTestimonial">
        <h3 class="mt-4 text-base">
          Response: Yes
        </h3>
        <vs-input
          :value="form.surveyQuestionYesResponse"
          label="Feedback Request"
          class="w-full mt-1"
          :disabled="isCompanyUser"
          @input="(value) => setFormValue('surveyQuestionYesResponse', value)"
        />

        <vs-input
          :value="form.photoRequestHeading"
          label="Photo Request Heading"
          class="w-full mt-3"
          :disabled="isCompanyUser"
          @input="(value) => setFormValue('photoRequestHeading', value)"
        />

        <vs-input
          :value="form.photoRequestContent"
          label="Photo Request Content"
          class="w-full mt-3"
          :disabled="isCompanyUser"
          @input="(value) => setFormValue('photoRequestContent', value)"
        />
      </template>

      <template v-if="isReview">
        <h3 class="mt-4 text-base">
          {{ (funnel.filterNegativeSentiment) ? 'Response: Yes' : 'Survey Review' }}
        </h3>

        <vs-input
          :value="form.reviewRequestHeading"
          :label="(funnel.filterNegativeSentiment) ? 'Review Request Heading' : 'Survey Response'"
          class="w-full mt-1"
          :disabled="isCompanyUser"
          @input="(value) => setFormValue('reviewRequestHeading', value)"
        />

        <vs-input
          :value="form.reviewRequestContent"
          label="Review Request Content"
          class="w-full mt-3"
          :disabled="isCompanyUser"
          @input="(value) => setFormValue('reviewRequestContent', value)"
        />

        <div
          v-if="locations.length > 1"
          class="mt-3"
        >
          <label class="vs-input--label">Location Shown in Preview</label>
          <rj-select
            v-model="locationId"
            label="name"
            :options="locations"
            :reduce="location => location.id"
            :clearable="false"
            :disabled="isCompanyUser"
            placeholder="Choose a location..."
          />
        </div>
      </template>
    </div>

    <div class="vx-col w-full sm:w-5/12">
      <rj-phone-browser-screen>
        <rj-survey-app-preview
          v-if="funnel.surveyQuestionText"
          :funnel="funnel"
          :company="company"
          :preview-started.sync="previewStarted"
        />
      </rj-phone-browser-screen>

      <button
        v-if="previewStarted"
        class="px-4 py-2 outline-none mx-auto mt-2 flex items-center justify-center flex-grow rounded-lg shadow-md text-white"
        :style="{ 'background-color': colors.darkPurple }"
        @click="previewStarted = false"
      >
        <feather-icon
          icon="SkipBackIcon"
          svg-classes="w-4 h-4"
        />
        <span class="ml-2 text-sm">Reset</span>
      </button>
    </div>
  </div>
</template>

<script>
import Color from 'color';
import _debounce from 'lodash/debounce';
import { colors as themeColors } from '@/../themeConfig';
import { isPristine, hasKey, httpBuildQuery } from '@/utils';
import RjOfferImageUploader from '@/components/RjOfferImageUploader.vue';
import RjPhoneBrowserScreen from '@/views/common/funnels/smartphone/RjPhoneBrowserScreen.vue';
import RjSurveyAppPreview from '@/views/common/funnels/survey-preview/RjSurveyAppPreview.vue';

export default {
  name: 'RjFunnelSurveyForm',

  components: {
    RjSurveyAppPreview,
    RjPhoneBrowserScreen,
    RjOfferImageUploader,
  },

  props: {
    funnel: {
      type: Object,
      required: true,
    },

    company: {
      type: Object,
      required: true,
    },

    locations: {
      type: Array,
      required: true,
    },

    isCompanyUser: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      old: {},
      form: {
        surveyQuestionText: null,
        surveyQuestionNoResponse: null,
        offerTitle: null,
        offerBody: null,
        offerDetails: null,
        offerShareMethod1: null,
        offerShareMethod2: null,
        offerShareMethod3: null,
        offerShareSubject: null,
        offerShareBody: null,
        offerUrl: null,
        photoRequestHeading: null,
        photoRequestContent: null,
        reviewRequestHeading: null,
        reviewRequestContent: null,
        surveyQuestionYesResponse: null,
      },
      pristine: true,
      offerImageData: null,
      previewStarted: false,
      offerImage: this.funnel.offerImage,
      locationId: (this.locations.length >= 1) ? this.locations[0].id : null,
      shareMethods: [
        { name: 'Facebook' },
        { name: 'Email' },
        { name: 'SMS' },
        { name: 'Twitter' },
      ],
    };
  },

  computed: {
    isReferral() {
      return this.funnel.type === 'Referral';
    },

    isReview() {
      return this.funnel.type === 'Review';
    },

    isTestimonial() {
      return this.funnel.type === 'Testimonial';
    },

    colors() {
      const { rjPurple } = themeColors;

      return {
        darkPurple: rjPurple,
        lightPurple: Color(rjPurple).lightness(93).hex(),
      };
    },

    offerImageSrc() {
      if (this.offerImageData) {
        return this.offerImageData;
      }

      return this.offerImage
        ? `${process.env.VUE_APP_RJ_OFFER_IMAGES_URL}/${this.funnel.companyId}/${this.offerImage}`
        : '';
    },

    availableShareMethods() {
      return this.shareMethods.filter((s) => ![this.form.offerShareMethod1, this.form.offerShareMethod2, this.form.offerShareMethod3].includes(s.name));
    },
  },

  watch: {
    form: {
      handler: 'hasChanges',
      deep: true,
    },

    locationId: {
      handler: 'fetchLocation',
      immediate: true,
    },
  },

  created() {
    this.buildFormObject();
  },

  methods: {
    fetchLocation(locationId) {
      if (locationId === null) {
        return;
      }

      const filters = httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: { active: true },
          include: [
            {
              relation: 'locationReviewSites',
              scope: { include: ['reviewSite'] },
            },
          ],
        }),
      });

      this.$store.dispatch('locations/fetchLocation', { locationId, filters });
    },

    /**
     * Resets the survey form.
     *
     * @return {void}
     */
    reset() {
      this.form = { ...this.old };
      this.offerImageData = null;

      this.$store.commit('funnels/SET_OFFER_IMAGE', null);
      this.$store.commit('funnels/SET_FUNNEL', { ...this.funnel, ...this.form });
    },

    onSelectOfferImage(payload) {
      if (!payload || this.isCompanyUser) {
        this.offerImageData = null;
        this.$store.commit('funnels/SET_OFFER_IMAGE', null);
        return;
      }

      this.offerImageData = payload.fileData;
      this.$store.commit('funnels/SET_OFFER_IMAGE', payload.file);
      this.pristine = false;
    },

    setFormValue(prop, value) {
      this.form[prop] = value;
      this.$store.commit('funnels/UPDATE_FUNNEL_PROP', { prop, value });
    },

    buildFormObject() {
      for (const key in this.funnel) {
        if (hasKey(this.funnel, key) && hasKey(this.form, key)) {
          this.form[key] = this.funnel[key];
        }
      }

      if (this.funnel.offerImage) {
        this.$store.commit('funnels/SET_OFFER_IMAGE', this.offerImage);
      } else {
        this.$store.commit('funnels/SET_OFFER_IMAGE', null);
      }

      this.old = { ...this.form };
    },

    /**
     * Checks if the form has been changed.
     *
     * @param  {Object} original
     * @return {void}
     */
    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(current) {
      this.pristine = isPristine(current, this.old);
    }, 150),
  },
};
</script>
