<template>
  <vs-prompt
    :title="title"
    :active="active"
    :buttons-hidden="pristine"
    accept-text="Save"
    class="rj-confirm-modal"
    @accept="submit"
    @close="close"
  >
    <form class="space-y-3">
      <template v-if="!hasReviewSite">
        <div>
          <label class="vs-input--label">Review Site</label>
          <rj-select
            v-model="form.reviewSiteId"
            label="name"
            :options="reviewSites"
            :reduce="reviewSite => reviewSite.id"
            :clearable="false"
            placeholder="Choose a review site..."
          />
        </div>
      </template>

      <template v-if="form.identifier || selectedReviewSite.code === 'google'">
        <div v-if="googlePlaces">
          <div v-if="googlePlaces && googlePlaces.length && !manualPlaceId">
            <label class="vs-input--label">Google Place Id</label>
            <rj-select
              v-model="form.identifier"
              label="name"
              :options="googlePlaces"
              :reduce="place => place.placeId"
              placeholder="Choose a Google Place"
            />
          </div>
          <vs-input
            v-else
            v-model="form.identifier"
            label="Google Place Id"
            class="w-full"
          />
          <vs-checkbox
            :value="manualPlaceId"
            class="m-0 pr-2 py-2"
            @input="(value) => manualPlaceId = value"
          >
            Enter Google Place Id Manually
          </vs-checkbox>
        </div>
        <div v-else>
          <p>Fetching Google Places...</p>
        </div>
      </template>

      <template v-else-if="selectedReviewSite.id">
        <vs-input
          v-model="form.profileUrl"
          label="Published Reviews URL"
          class="w-full"
        />
      </template>

      <template v-if="selectedReviewSite.code !== 'google' && selectedReviewSite.hasSeparateNewReviewUrl">
        <vs-input
          v-model="form.newReviewUrl"
          label="New Review URL"
          class="w-full"
        />
      </template>
    </form>
  </vs-prompt>
</template>

<script>
import _debounce from 'lodash/debounce';
import { isEmptyObject, isPristine } from '@/utils';

export default {
  name: 'RjReviewSiteDialog',

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    locationReviewSite: {
      type: Object,
      required: true,
      default: () => {},
    },

    reviewSites: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      pristine: true,
      form: {
        reviewSiteId: null,
        name: null,
        code: null,
        identifier: this.locationReviewSite.identifier || '',
        profileUrl: '',
        newReviewUrl: '',
      },
      old: {},
      googlePlaces: null,
      manualPlaceId: false,
    };
  },

  computed: {
    hasReviewSite() {
      return !isEmptyObject(this.locationReviewSite);
    },

    action() {
      return this.hasReviewSite ? 'edit' : 'add';
    },

    selectedReviewSite() {
      if (!this.form.reviewSiteId) {
        return {};
      }
      return this.reviewSites.find((r) => r.id === this.form.reviewSiteId);
    },

    title() {
      const { name } = this.locationReviewSite;
      if (name) {
        return (this.action === 'add') ? `Add ${name}` : `Edit ${name}`;
      }
      return this.action === 'add' ? 'Add Review Site' : 'Edit Review Site';
    },

    newReviewUrl() {
      const newReviewPath = this.selectedReviewSite.newReviewPath ?? '';

      if (['indeed', 'glassdoor'].includes(this.selectedReviewSite.code)) {
        return newReviewPath;
      }

      if (this.selectedReviewSite.hasSeparateNewReviewUrl) {
        return this.form.newReviewUrl;
      }

      return `${this.form.profileUrl}${newReviewPath}`;
    },
  },

  watch: {
    form: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  created() {
    this.old = { ...this.locationReviewSite };

    if (this.hasReviewSite) {
      this.form = { ...this.locationReviewSite };
    }
  },

  methods: {
    async fetchGooglePlaces() {
      const { id } = this.$store.getters['companies/company'];
      const { accessToken } = await this.$store.dispatch('companies/refreshGmbToken', id);

      if (accessToken) {
        const { accounts, error } = await this.$store.dispatch('companies/getGmbAccounts', accessToken);
        if (error) {
          this.googlePlaces = [];
          this.manualPlaceId = true;
          return;
        }

        const getLocations = [];

        for (const account of accounts) {
          getLocations.push(this.$store.dispatch('companies/getGmbLocations', {
            accessToken,
            accountName: account.name,
          }));
        }

        const locationResults = await Promise.all(getLocations);
        const googlePlaces = [];
        let placeFound = false;

        for (const result of locationResults) {
          const { locations = [], error } = result;
          if (error) {
            continue;
          }

          for (const location of locations) {
            const { metadata, title, storefrontAddress } = location;
            let name = title;
            if (storefrontAddress && storefrontAddress.locality) {
              name = `${name} - ${storefrontAddress.locality}`;
            }
            if (metadata?.placeId) {
              googlePlaces.push({
                placeId: metadata.placeId,
                name,
              });
              const { identifier = '' } = this.locationReviewSite;
              if (identifier === metadata.placeId) {
                placeFound = true;
              }
            }
          }
        }
        this.googlePlaces = googlePlaces;
        if (this.googlePlaces.length && !placeFound && this.action === 'edit') {
          this.manualPlaceId = true;
        }
      } else {
        this.googlePlaces = [];
        this.manualPlaceId = true;
      }
    },
    /**
     * Decides which action the modal should take.
     *
     * @return {void}
     */
    submit() {
      return this.action === 'add' ? this.add() : this.update();
    },

    /**
     * Adds a new Review Site.
     *
     * @return {void}
     */
    add() {
      this.$emit('review-site-added', { ...this.form, newReviewUrl: this.newReviewUrl });
      this.close();
    },

    /**
     * Updates the current Review Site.
     *
     * @return {void}
     */
    update() {
      this.$emit('review-site-updated', { ...this.form, newReviewUrl: this.newReviewUrl });
      this.close();
    },

    /**
     * Checks if the form has been changed.
     *
     * @param  {Object} original
     * @return {void}
     */
    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(original) {
      if (!this.googlePlaces) {
        this.fetchGooglePlaces();
      }
      if (this.selectedReviewSite.code !== 'google') {
        this.form.identifier = '';
      }
      this.pristine = isPristine(original, this.old);
    }, 150),

    /**
     * Programatic way of closing the modal.
     *
     * @return {void}
     */
    close() {
      this.$emit('update:active', false);
    },
  },
};


</script>
