<template>
  <vs-prompt
    title="Upgrade User Licenses"
    :active="active"
    :buttons-hidden="pristine"
    :accept-text="acceptText"
    class="rj-confirm-modal"
    @accept="submit"
    @close="close"
  >
    <p>How many users licenses do you want?</p>

    <div class="flex my-2">
      <div
        v-if="amount"
        class="w-32"
      >
        <rj-input-number
          v-if="loaded"
          v-model.number="amount"
          :step="5"
          :min="allowedPerLocation"
        />
      </div>
    </div>

    <p
      v-if="unitPrice"
      class="text-sm"
    >
      <span v-if="amount > current">You will be charged a prorated amount of {{ formattedProrated }} now and an extra {{ formattedCost }} USD monthly
        for this account beginning on the next {{ renewalDate }} of the month.</span>
      <span v-if="amount < current">Available User Licenses will be reduced immediately and your future monthly invoices will be reduced by {{ formattedCost }} USD.</span>
    </p>
  </vs-prompt>
</template>

<script>
import { format } from 'date-fns';
import RjInputNumber from '@/components/RjInputNumber.vue';
import { EXTRA_5_USERS_CODE } from '@/rj_consts';
import { httpBuildQuery } from '@/utils';

export default {
  name: 'RjUsersPackModal',

  components: { RjInputNumber },

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    counts: {
      type: Object,
      required: true,
    },

    maxPerLocation: {
      type: Number,
      required: false,
      default: 5,
    },
  },

  data() {
    return {
      amount: 0,
      proratedUnitAmount: 0,
      renewalDate: '',
      unitPrice: 0,
      submitting: false,
      loaded: false,
    };
  },

  computed: {
    cost() {
      return (this.pristine) ? 0 : Math.abs((this.amount - this.current) * (1 / 5) * this.unitPrice);
    },

    formattedCost() {
      return this.formatCentsAsDollars(this.cost);
    },

    formattedProrated() {
      let amount = this.proratedUnitAmount * (this.amount - this.current) * (1 / 5);
      // minimum $5.00
      if (amount > 0 && amount < 500) {
        amount = 500;
      }
      return this.formatCentsAsDollars(amount);
    },

    pristine() {
      return this.amount === this.current;
    },

    current() {
      return this.counts.locations * this.maxPerLocation + this.counts.usersAddOns * this.maxPerLocation;
    },

    allowedPerLocation() {
      return (this.maxPerLocation * this.counts.locations);
    },

    acceptText() {
      if (this.submitting) {
        return 'Processing...';
      }
      return 'Update';
    },
  },

  async mounted() {
    this.amount = this.counts.locations * this.maxPerLocation + this.counts.usersAddOns * this.maxPerLocation;
    const { unitPrice, amount, renewalDate } = await this.$store.dispatch('partners/getProratedAmountForAddOn', EXTRA_5_USERS_CODE);
    this.renewalDate = format(renewalDate, 'do');
    this.proratedUnitAmount = amount;
    this.unitPrice = unitPrice;
    this.loaded = true;
  },

  methods: {
    async submit() {
      if (this.submitting) {
        return;
      }
      if (this.amount > this.current) {
        await this.increaseQuantity();
      } else if (this.amount < this.current) {
        await this.decreaseQuantity();
      }
      this.close();
    },

    async increaseQuantity() {
      const companyId = this.$store.getters['companies/company'].id;
      const payload = {
        code: EXTRA_5_USERS_CODE,
        quantity: (this.amount - this.current) / 5,
        companyId,
      };
      this.submitting = true;
      const { error, errorMessage } = await this.$store.dispatch('partners/addAddOn', payload);
      if (error) {
        this.$vs.notify({ title: 'Error', text: errorMessage || error, color: 'danger' });
      } else {
        this.$vs.notify({ title: 'Success', text: 'Extra User Licenses purchased.', color: 'success' });
        const filters = httpBuildQuery({
          filter: JSON.stringify({
            include: [
              'plan',
              {
                relation: 'companyPlans',
                scope: {
                  where: {
                    active: true,
                  },
                  include: ['plan'],
                },
              },
              {
                relation: 'locations',
                scope: { where: { pending: false } },
              },
            ],
          }),
        });
        await this.$store.dispatch('companies/getCompany', { companyId, filters });
      }
    },

    async decreaseQuantity() {
      const companyId = this.$store.getters['companies/company'].id;
      const payload = {
        code: EXTRA_5_USERS_CODE,
        quantity: (this.current - this.amount) / 5,
        companyId,
      };
      this.submitting = true;
      const { error, errorMessage } = await this.$store.dispatch('partners/removeAddOn', payload);
      if (error) {
        this.$vs.notify({ title: 'Error', text: errorMessage || error, color: 'danger' });
      } else {
        this.$vs.notify({ title: 'Success', text: 'Extra User Licenses removed.', color: 'success' });
        const filters = httpBuildQuery({
          filter: JSON.stringify({
            include: [
              'plan',
              {
                relation: 'companyPlans',
                scope: {
                  where: {
                    active: true,
                  },
                  include: ['plan'],
                },
              },
              {
                relation: 'locations',
                scope: { where: { pending: false } },
              },
            ],
          }),
        });
        await this.$store.dispatch('companies/getCompany', { companyId, filters });
      }
    },

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
