import { LOW_JUMP_CODE, HIGH_JUMP_CODE } from '@/rj_consts';

const normalize = function(ability) {
  return Object.entries(ability).map((entry) => {
    const [domain, actions] = entry;
    return { domain, actions: (typeof actions === 'string') ? [actions] : actions };
  })[0];
};

export default {
  computed: {
    $_guard_permissions() {
      const { permissions } = this.$store.getters['auth/permissions'];
      return permissions;
    },

    $_guard_role() {
      return this.$store.getters['auth/currentRole'];
    },

    $_guard_partnerPlan() {
      return this.$store.getters['partners/plan'];
    },

    $_guard_companyPlan() {
      return this.$store.getters['companies/plan'];
    },

    $_guard_features() {
      if (!this.$_guard_companyPlan) {
        return this.$store.getters['revenuejump/features'];
      }

      return this.$store.getters['revenuejump/features'][this.$_guard_companyPlan.id];
    },

    isLowJump() {
      return this.guard_company_plan(LOW_JUMP_CODE);
    },

    isHighJump() {
      return this.guard_company_plan(HIGH_JUMP_CODE);
    },
  },

  methods: {
    /**
     * Checks whether the user has the ability to perform an acion.
     *
     * @param  {Object} ability
     * @param  {closure} callback
     * @return {bool}
     */
    guard_can(ability, callback = null) {
      if (typeof ability !== 'object') {
        throw new Error('Guard needs the ability to be an object');
      }

      if (!this.$_guard_permissions) {
        return false;
      }

      if (callback !== null) {
        return callback(this.$_guard_permissions, ability);
      }

      const { domain, actions } = normalize(ability);

      if (actions[0] === '*') {
        return true;
      }

      return actions.every((action) => this.$_guard_permissions[domain][action]);
    },

    /**
     * Checks whether the user has a given role.
     *
     * @param  {string|array} roles
     * @param  {closure} callback
     * @return {bool}
     */
    guard_is(roles, callback = null) {
      if (callback !== null) {
        return callback(this.$_guard_role, roles);
      }

      const _roles = (typeof roles === 'string') ? [roles] : roles;

      return _roles.some((role) => this.$_guard_role === role);
    },

    /**
     * Checks whether the partner is in a given plan
     *
     * @param  {string|array} plans
     * @param  {closure} callback
     * @return {boolean}
     */
    guard_partner_plan(plans, callback = null) {
      if (callback !== null) {
        return callback(this.$_guard_partnerPlan, plans);
      }

      const _plans = (typeof plans === 'string') ? [plans] : plans;
      return _plans.some((plan) => this.$_guard_partnerPlan.name === plan);
    },

    /**
     * Checks whether a company is in a given plan
     *
     * @param  {string|array} plans
     * @param  {closure} callback
     * @return {boolean}
     */
    guard_company_plan(plans, callback = null) {
      if (callback !== null) {
        return callback(this.$_guard_companyPlan, plans);
      }

      const _plans = (typeof plans === 'string') ? [plans] : plans;

      return _plans.some((plan) => this.$_guard_companyPlan.code === plan);
    },

    /**
     * Checks whether the given feature is allowed for the current plan
     *
     * @param  {inetger} plan
     * @param  {string} feature
     * @param  {integer} amount
     * @return {boolean}
     */
    guard_company_plan_allows(feature, amount, callback = null) {
      if (!this.$_guard_features[feature]) {
        throw new Error(`There's no "${feature}" present for the given plan`);
      }

      if (callback !== null) {
        return callback(this.$_guard_features[feature], amount);
      }

      return this.$_guard_features[feature] > amount;
    },
  },
};
