<template>
  <vx-card title="Overview">
    <template v-if="isAdminUser">
      <rj-admin-overview-panel v-bind="{ plan, counts, company, partner }" />
    </template>

    <template v-if="isCompanyUser">
      <rj-company-overview-panel v-bind="{ plan, counts, company, partner }" />
    </template>
  </vx-card>
</template>

<script>
import Authorizable from '@/mixins/Authorizable';
import RjAdminOverviewPanel from '@/views/common/companies/partials/RjAdminOverviewPanel.vue';
import RjCompanyOverviewPanel from '@/views/common/companies/partials/RjCompanyOverviewPanel.vue';

export default {
  name: 'RjCompanyOverview',

  components: {
    RjAdminOverviewPanel,
    RjCompanyOverviewPanel,
  },

  mixins: [Authorizable],

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isCompanyUser() {
      return this.guard_is('Company', (role) => /^Company/.test(role));
    },

    isAdminUser() {
      return !this.isCompanyUser;
    },

    partner() {
      return this.$store.getters['partners/partner'];
    },

    plan() {
      return this.$store.getters['companies/plan'];
    },

    counts() {
      return {
        sms: this.$store.getters['companies/sms'],
        users: this.$store.getters['companies/usersCount'],
        locations: this.$store.getters['companies/locationsCount'],
        usersAddOns: this.$store.getters['companies/extraUsersAddOns'].length,
        smsAddOns: this.$store.getters['companies/extraSmsAddOns'].length,
      };
    },
  },
};
</script>
