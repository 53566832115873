<template functional>
  <div class="flex items-center space-x-3">
    <template v-if="!props.pristine">
      <vs-button
        type="border"
        @click="listeners['reset-funnel']"
      >
        Reset
      </vs-button>
      <vs-button @click="listeners['save-funnel']">
        Save
      </vs-button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'RjFunnelFormActions',

  props: {
    pristine: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
