<template>
  <section class="py-5 my-auto">
    <div class="flex justify-center pb-4">
      <img
        :src="logo"
        :alt="`${company.name} Logo`"
        class="w-3/5"
      >
    </div>

    <template v-if="sentiment === null">
      <div class="vx-card px-2 py-4 rounded-lg">
        <p class="text-sm text-center font-bold leading-tight">
          {{ funnel.surveyQuestionText }}
        </p>

        <div class="flex justify-center space-x-2 mt-4">
          <vs-button
            type="border"
            :color="colors.rjGray500"
            :text-color="colors.rjGray500"
            class="px-4 py-2 text-xs"
            @click="sentiment = 0"
          >
            No
          </vs-button>
          <vs-button
            color="success"
            class="px-4 py-2 text-xs"
            @click="sentiment = 1"
          >
            Yes
          </vs-button>
        </div>
      </div>
    </template>

    <template v-if="sentiment === 1 && funnel.type === 'Testimonial' && !completed">
      <div class="vx-card px-2 py-4 rounded-lg">
        <rj-testimonial-survey-preview
          :funnel="funnel"
          @completed="completed = true"
        />
      </div>
    </template>

    <template v-if="sentiment === 1 && funnel.type === 'Review' && !completed">
      <div class="vx-card px-2 py-4 rounded-lg">
        <rj-review-survey-preview
          :funnel="funnel"
          @completed="completed = true"
        />
      </div>
    </template>

    <template v-if="sentiment === 1 && funnel.type === 'Referral' && !completed">
      <div class="vx-card rounded-lg static">
        <rj-referral-survey-preview
          :funnel="funnel"
          @completed="completed = true"
        />
      </div>
    </template>

    <template v-if="sentiment === 0 && !completed">
      <div class="vx-card px-2 py-4 rounded-lg">
        <p class="text-sm text-center font-bold leading-tight">
          {{ funnel.surveyQuestionNoResponse }}
        </p>

        <vs-textarea
          height="54px"
          class="mb-0 p-2 text-xs mt-3"
          placeholder="Your feedback will be very helpful..."
        />

        <div class="flex justify-center mt-3">
          <vs-button
            size="small"
            color="success"
            @click="completed = true"
          >
            Send
          </vs-button>
        </div>
      </div>
    </template>

    <template v-if="sentiment !== null && completed">
      <div class="vx-card px-2 py-4 rounded-lg">
        <p class="text-sm text-center font-bold leading-tight">
          Thank you for the feedback, {{ auth.user.firstName }}.
          We really appreciate it!
        </p>
      </div>
    </template>
  </section>
</template>

<script>
import { colors } from '@/../themeConfig';
import RjReviewSurveyPreview from '@/views/common/funnels/survey-preview/RjReviewSurveyPreview.vue';
import RjReferralSurveyPreview from '@/views/common/funnels/survey-preview/RjReferralSurveyPreview.vue';
import RjTestimonialSurveyPreview from '@/views/common/funnels/survey-preview/RjTestimonialSurveyPreview.vue';

export default {
  name: 'RjSurveyAppPreview',

  components: {
    RjReviewSurveyPreview,
    RjReferralSurveyPreview,
    RjTestimonialSurveyPreview,
  },

  props: {
    funnel: {
      type: Object,
      required: true,
    },

    company: {
      type: Object,
      required: true,
    },

    previewStarted: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      sentiment: null,
      completed: false,
    };
  },

  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    colors() {
      return colors;
    },

    logo() {
      const { id, logo } = this.company;
      if (logo && /https?:\/\//.test(logo)) {
        return logo;
      }
      if (logo) {
        return `${process.env.VUE_APP_RJ_COMPANY_LOGO_URL}/${id}/${logo}`;
      }
      return null;
    },
  },

  watch: {
    sentiment: {
      handler: 'canReset',
    },

    previewStarted: {
      handler: 'reset',
    },
  },

  methods: {
    canReset(sentiment) {
      if (sentiment !== null) {
        this.$emit('update:previewStarted', true);
      }
    },

    reset(started) {
      if (started === false) {
        this.sentiment = null;
        this.completed = false;
      }
    },
  },
};
</script>
