<!--
 * File Name: TheNavbarHorizontal.vue
 * Description: The topnav for Main.vue.
 * Component Name: TheNavbarHorizontal
 * Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
 * Author: Pixinvent
 * Author URL: http://www.themeforest.net/user/pixinvent
-->

<template>
  <div class="relative">
    <div
      class="vx-navbar-wrapper"
      :class="classObj"
    >
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton"
        :color="navbarColorLocal"
        :class="textColor"
      >
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer p-2"
          icon="MenuIcon"
          @click.stop="showSidebar"
        />
        <div v-if="isAdminOrPartner">
          <span
            class="text-lg font-bold"
          >Company:</span><span class="text-lg pl-2">{{ companyName }}</span>
        </div>
        <vs-spacer />
        <span
          v-for="item in navMenuItems"
          :key="item.url"
        >
          <router-link
            v-if="item.url !== null"
            :to="item.url"
            :title="item.title || ''"
          >
            <feather-icon
              class="cursor-pointer p-2"
              :icon="item.icon"
              :style="{
                color: themeColors[item.color || 'primary']
              }"
            />
          </router-link>

          <component
            :is="item.component"
            v-else-if="item.component"
          />

          <feather-icon
            v-else
            class="cursor-pointer p-2"
            :icon="item.icon"
            :title="item.title || ''"
            :style="{
              color: themeColors[item.color || 'primary']
            }"
            @click.capture="item.action ? item.action($event) : null"
          />
        </span>
        <!-- <profile-drop-down /> -->
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import { colors as themeColors } from '@/../themeConfig';
import NotificationDropDown from './components/NotificationDropDown.vue';
// import ProfileDropDown from './components/ProfileDropDown.vue';

export default {
  name: 'TheNavbarHorizontal',
  components: {
    NotificationDropDown,
    // ProfileDropDown,
  },
  props: {
    navbarColor: {
      type: String,
      default: '#fff',
    },
    navMenuItems: {
      type: Array,
      default: () => [],
    },
    isAdminOrPartner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      themeColors,
    };
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return { 'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark') };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // Navbar Style
    classObj() {
      if (this.verticalNavMenuWidth === 'default') {
        return 'navbar-default';
      }
      if (this.verticalNavMenuWidth === 'reduced') {
        return 'navbar-reduced';
      }
      if (this.verticalNavMenuWidth) {
        return 'navbar-full';
      }
      return '';
    },

    companyName() {
      const company = this.$store.getters['companies/company'];
      if (!company) {
        return '';
      }
      return company.name;
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    },
  },
};
</script>
