<template>
  <rj-phone-previewer>
    <img
      :src="require('@/assets/images/phone/email-top.png')"
      :srcset="`${require('@/assets/images/phone/email-top.png')} 1x, ${require('@/assets/images/phone/email-top@2x.png')} 2x`"
      alt="Email Top Bar"
    >
    <div class="smartphone__email-screen">
      <div class="flex items-center w-full leading-tight border-b border-gray-300 mt-2 pb-2">
        <span class="w-8 h-8 rounded-full flex items-center justify-center bg-gray-700 text-white font-bold flex-shrink-0 text-xs">
          {{ initials }}
        </span>
        <div class="ml-2 text-sm truncate">
          <p class="font-bold truncate">
            {{ survey.surveyTemplate.message1EmailFromName }}
          </p>
          <p class="text-gray-500">
            <span class="text-gray-600 text-gray-700 font-semibold">To:</span> {{ survey.contact.firstName }} {{ survey.contact.lastName || '' }}
          </p>
        </div>
      </div>


      <div class="mt-2 text-gray-700 font-bold">
        <p class="leading-tight text-sm">
          {{ survey.surveyTemplate.message1EmailFromSubject }}
        </p>
      </div>

      <div class="text-sm mt-2">
        <!-- eslint-disable -->
        <p class="font-serif leading-tight whitespace-pre-line" v-html="message.text" />
        <!-- eslint-enable -->
      </div>
    </div>
    <img
      :src="require('@/assets/images/phone/email-bottom.png')"
      :srcset="`${require('@/assets/images/phone/email-bottom.png')} 1x, ${require('@/assets/images/phone/email-bottom@2x.png')} 2x`"
      alt="Email Bottom Bar"
    >
  </rj-phone-previewer>
</template>

<script>
import RjPhonePreviewer from '@/views/common/funnels/smartphone/RjPhonePreviewer.vue';

export default {
  name: 'RjPhoneEmailScreen',

  components: { RjPhonePreviewer },

  props: {
    survey: {
      type: Object,
      required: true,
    },

    message: {
      type: Object,
      required: true,
    },
  },

  computed: {
    initials() {
      return this.survey.surveyTemplate.message1EmailFromName
        .match(/(^\S\S?|\b\S)?/g).join('')
        .match(/(^\S|\S$)?/g).join('')
        .toUpperCase();
    },

    company() {
      return this.$store.getters['companies/company'];
    },

    companyLogoUrl() {
      const { id, logo } = this.company;
      if (logo && /https?:\/\//.test(logo)) {
        return logo;
      }
      if (logo) {
        return `${process.env.VUE_APP_RJ_COMPANY_LOGO_URL}/${id}/${logo}`;
      }
      return '';
    },

    signature() {
      const {
        emailSignatureOne = '',
        emailSignatureTwo = '',
        emailSignatureThree = '',
        emailSignatureFour = '',
      } = this.company;
      return {
        line_one: emailSignatureOne,
        line_two: emailSignatureTwo,
        line_three: emailSignatureThree,
        line_four: emailSignatureFour,
      };
    },
  },
};
</script>
