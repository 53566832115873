<template>
  <div>
    <vx-card title="Details">
      <!-- Name -->
      <vs-input
        :value="form.name"
        label="Funnel Name"
        class="w-full"
        @input="(value) => setFormValue('name', value)"
      />

      <div class="mt-3">
        <label class="vs-input--label">Funnel Type</label>
        <rj-select
          :value="form.type"
          :options="types"
          :reduce="type => type.type"
          :clearable="false"
          :disabled="mode === 'edit' || isCompanyUser"
          placeholder="Choose a type..."
          @input="(value) => setFormValue('type', value)"
        />
      </div>

      <div class="mt-3">
        <label class="vs-input--label">Status</label>
        <vs-switch
          :value="form.active"
          @input="(value) => setFormValue('active', value)"
        >
          <span slot="on">ACTIVE</span>
          <span slot="off">INACTIVE</span>
        </vs-switch>
      </div>

      <template v-if="isReview">
        <div class="mt-4">
          <vs-checkbox
            :value="form.filterNegativeSentiment"
            class="m-0"
            @input="(value) => setFormValue('filterNegativeSentiment', value) "
          >
            Filter Negative Sentiment
          </vs-checkbox>
        </div>
      </template>

      <div class="mt-3">
        <label class="vs-input--label">Allow Duplicate Surveys</label>
        <rj-select
          :value="form.allowDuplicatesAfter"
          :options="duplicates"
          :reduce="timestamp => timestamp.id"
          :clearable="false"
          placeholder="Choose a time range..."
          @input="(value) => setFormValue('allowDuplicatesAfter', value)"
        />
      </div>

      <!-- From Email Address -->
      <vs-input
        :value="form.fromEmail"
        label="From Email Address"
        class="w-full mt-3"
        @input="(value) => setFormValue('fromEmail', value)"
      />

      <!-- From Name -->
      <vs-input
        :value="form.fromName"
        label="From Name"
        class="w-full mt-3"
        @input="(value) => setFormValue('fromName', value)"
      />

      <!-- Reply To Email Address -->
      <vs-input
        :value="form.replyToEmailAddress"
        label="Reply To Email Address"
        class="w-full mt-3"
        @input="(value) => setFormValue('replyToEmailAddress', value)"
      />
    </vx-card>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import { isPristine, hasKey } from '@/utils';
import Authorizable from '@/mixins/Authorizable';

export default {
  name: 'RjFunnelForm',

  mixins: [Authorizable],

  props: {
    mode: {
      type: String,
      required: true,
    },

    isCompanyUser: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      pristine: true,
      old: {},
      form: {
        id: null,
        name: '',
        type: '',
        active: false,
        filterNegativeSentiment: '',
        allowDuplicatesAfter: 0,
        fromEmail: '',
        fromName: '',
        replyToEmailAddress: '',
      },
      duplicates: [
        { id: 0, label: 'Never' },
        { id: 2629746, label: '1 Month' },
        { id: 7889238, label: '3 Months' },
        { id: 15778463, label: '6 Months' },
        { id: 31556952, label: '1 Year' },
      ],
    };
  },

  computed: {
    types() {
      if (this.isLowJump) {
        return [{ type: 'Testimonial', label: 'Testimonial' }];
      }

      return [
        { type: 'Review', label: 'Review' },
        { type: 'Referral', label: 'Referral' },
        { type: 'Testimonial', label: 'Testimonial' },
      ];
    },

    funnel() {
      return this.$store.getters['funnels/funnel'];
    },

    isReview() {
      return this.funnel.type === 'Review';
    },

    isReferral() {
      return this.funnel.type === 'Referral';
    },

    isTestimonial() {
      return this.funnel.type === 'Testimonial';
    },
  },

  watch: {
    'form': {
      handler: 'hasChanges',
      deep: true,
    },

    'form.type': {
      handler: 'resetSentiment',
    },
  },

  mounted() {
    this.buildFormObject();
  },

  methods: {
    buildFormObject() {
      for (const key in this.funnel) {
        if (hasKey(this.funnel, key) && hasKey(this.form, key)) {
          this.form[key] = this.funnel[key];
        }
      }

      this.old = { ...this.form };
    },

    resetSentiment(type) {
      if (type !== 'Review') {
        this.setFormValue('filterNegativeSentiment', true);
      }
    },

    setFormValue(prop, value) {
      this.form[prop] = value;
      this.$store.commit('funnels/UPDATE_FUNNEL_PROP', { prop, value });
    },

    /**
     * Reset the form to its original state.
     *
     * @return {void}
     */
    reset() {
      this.form = { ...this.old };
      this.$store.commit('funnels/SET_FUNNEL', { ...this.funnel, ...this.form });
    },

    /**
     * Checks if the form has been changed.
     *
     * @param  {Object} current
     * @return {void}
     */
    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(current) {
      this.pristine = isPristine(current, this.old);
    }, 150),
  },
};
</script>
