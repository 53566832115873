import _debounce from 'lodash/debounce';
import { httpBuildQuery, tap, isPristine } from '@/utils';
import defaultFunnels from '@/views/common/funnels/default-funnels';

export default {
  data() {
    return {
      loading: false,
      pristine: true,
      old: {},
      mode: 'create',
    };
  },

  computed: {
    funnel() {
      return this.$store.getters['funnels/funnel'];
    },

    hasOfferImage() {
      return this.$store.getters['funnels/hasOfferImage'];
    },

    offerImage() {
      return this.$store.getters['funnels/offerImage'];
    },

    valid() {
      return this.$store.getters['funnels/valid'];
    },
  },

  watch: {
    $route: {
      handler: 'init',
      immediate: true,
    },

    funnel: {
      handler: 'hasChanges',
      deep: true,
    },

    offerImage() {
      if (typeof this.offerImage === 'object') {
        this.pristine = false;
      }
    },
  },

  methods: {
    /**
     * Fetches the current compant and decides whether
     * the page is build to add or edit a funnel
     *
     * @param  {Object} $route
     * @return {Promise}
     */
    async init($route) {
      this.loading = true;

      if ($route.params.funnel !== 'create') {
        await this.fetch();
      } else {
        this.createDefaultFunnel();
      }

      this.old = { ...this.funnel };
    },

    /**
     * Fetch the funnel with the given id
     *
     * @return {Promise}
     */
    async fetch() {
      this.mode = 'edit';
      const id = this.$route.params.funnel;
      const filters = httpBuildQuery({ includeSurveyStats: 1 });

      await this.$store.dispatch('funnels/fetchFunnel', { id, filters });
      this.loading = false;
    },

    onSave() {
      return (this.mode === 'create') ? this.create() : this.update();
    },

    /**
     * Creates a new funnel
     *
     * @return {Promise}
     */
    async create() {
      try {
        const funnel = await this.$store.dispatch('funnels/createFunnel', {
          companyId: this.company.id,
          payload: this.buildPayload(),
        });

        if (funnel.errorMessage) {
          this.$vs.notify({ title: 'Error', text: funnel.errorMessage, color: 'danger' });
          return;
        }

        if (this.hasOfferImage) {
          await this.uploadOfferImage(funnel);
        }

        this.$vs.notify({ title: 'Success', text: 'Funnel saved.', color: 'success' });
        this.redirectTo(funnel);
        this.old = { ...this.funnel };
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    /**
     * Updates the current funnel
     *
     * @return {Promise}
     */
    async update() {
      try {
        const funnel = await this.$store.dispatch('funnels/updateFunnel', { id: this.funnel.id, payload: this.buildPayload() });

        if (funnel.errorMessage) {
          this.$vs.notify({ title: 'Error', text: funnel.errorMessage, color: 'danger' });
          return;
        }

        if (this.hasOfferImage) {
          await this.uploadOfferImage(this.funnel);
        }

        this.$vs.notify({ title: 'Success', text: 'Funnel saved.', color: 'success' });
        this.old = { ...this.funnel };
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async uploadOfferImage(funnel) {
      try {
        await this.$store.dispatch('funnels/updateOfferImage', { id: funnel.id, file: this.offerImage });
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * Creates the funnel skeleton
     *
     * @return {void}
     */
    createDefaultFunnel() {
      const companyName = this.company.name;
      tap(defaultFunnels({ companyName }), (funnel) => {
        this.$store.commit('funnels/SET_FUNNEL', { ...funnel, fromEmail: '', fromName: '' });
      });

      this.loading = false;
    },

    buildPayload() {
      return {
        ...this.funnel,
        message1EmailFromEmail: this.funnel.fromEmail,
        message2EmailFromEmail: this.funnel.fromEmail,
        message3EmailFromEmail: this.funnel.fromEmail,
        message1EmailFromName: this.funnel.fromName,
        message2EmailFromName: this.funnel.fromName,
        message3EmailFromName: this.funnel.fromName,
        replyToEmailAddress: this.funnel.replyToEmailAddress || this.funnel.fromEmail,
      };
    },

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(current) {
      this.pristine = isPristine(current, this.old);
    }, 150),
  },
};
