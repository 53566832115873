export default async function auth({ store, next }) {
  const authenticated = store.getters['auth/authenticated'];

  if (authenticated) {
    return next();
  }

  try {
    await store.dispatch('auth/me');
    await store.dispatch('roles/getRoles');
  } catch (err) {
    return next(false);
  }

  return next();
}
