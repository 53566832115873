<template>
  <div>
    <table class="w-full">
      <tr>
        <td class="font-semibold">
          Plan
        </td>
        <td>
          {{ plan.name }}
        </td>
        <td>
          <vs-button
            v-if="!company.downgradeAt"
            type="flat"
            icon-pack="feather"
            class="inline-block"
            :icon="isHighJump ? 'icon-arrow-down' : 'icon-arrow-up'"
            :title="isHighJump ? 'Downgrade Plan' : 'Upgrade Plan'"
            @click.stop="openPlanChangeDialog()"
          />
        </td>
      </tr>

      <tr>
        <td class="font-semibold">
          Status
        </td>
        <td>
          {{ company.active ? 'Active' : 'Inactive' }}
        </td>
        <td>
          <vs-button
            icon-pack="feather"
            :icon="company.active ? 'icon-slash' : 'icon-power'"
            type="flat"
            class="inline-block"
            :title="
              company.active ? 'Deactivate Account' : 'Activate Account'
            "
            @click.stop="openCancelAccountDialog"
          />
        </td>
      </tr>

      <tr>
        <td class="font-semibold">
          SMS Credits
        </td>
        <td>
          {{ smsCreditsLabel }}
        </td>
        <td>
          <vs-button
            icon-pack="feather"
            icon="icon-arrow-up"
            type="flat"
            class="inline-block"
            title="Upgrade SMS Credits"
            @click.stop="upgradeSmsPack"
          />
        </td>
      </tr>

      <tr>
        <td class="font-semibold">
          User Licenses
        </td>
        <td>
          {{ userLicensesLabel }}
        </td>
        <td>
          <vs-button
            icon-pack="feather"
            icon="icon-arrow-up"
            type="flat"
            class="inline-block"
            title="Upgrade Users"
            @click.stop="upgradeUsersPack"
          />
        </td>
      </tr>

      <tr>
        <td class="font-semibold">
          Survey Permissions
        </td>
        <td>
          {{ surveyPermissionStatusLabel }}
        </td>
        <td>
          <vs-button
            v-if="['Pending', 'Not Granted'].includes(surveyPermissionStatusLabel)"
            icon-pack="feather"
            icon="icon-mail"
            type="flat"
            class="inline-block"
            title="Request Permissions"
            @click="openSurveyPermissionDialog"
          />
        </td>
        <td>
          <button style="visibility: hidden; width: 32px; height: 32px;" />
        </td>
      </tr>
    </table>

    <vs-button
      color="primary"
      type="filled"
      class="mt-2 w-full"
      @click="openDashboard"
    >
      OPEN DASHBOARD
    </vs-button>

    <rj-users-pack-modal
      v-if="usersPackModal"
      :active.sync="usersPackModal"
      :counts="counts"
    />

    <rj-sms-pack-modal
      v-if="smsPackModal"
      :active.sync="smsPackModal"
      :counts="counts"
    />
  </div>
</template>

<script>
import { bus, httpBuildQuery } from '@/utils';
import * as codes from '@/rj_consts';
import Authorizable from '@/mixins/Authorizable';
import RjUsersPackModal from '@/views/common/users/modals/RjUsersPackModal.vue';
import RjSmsPackModal from '@/views/common/companies/modals/RjSmsPackModal.vue';

export default {
  name: 'RjAdminOverviewPanel',

  components: {
    RjSmsPackModal,
    RjUsersPackModal,
  },

  mixins: [Authorizable],

  props: {
    company: {
      type: Object,
      required: true,
    },

    plan: {
      type: Object,
      required: true,
    },

    partner: {
      type: Object,
      required: true,
    },

    counts: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      smsPackModal: false,
      usersPackModal: false,
    };
  },

  computed: {
    subscription() {
      return this.partner.subscriptions[0] || {};
    },

    smsCreditsLabel() {
      return `${this.counts.sms.used}/${this.counts.sms.max}`;
    },

    userAddOns() {
      return this.$store.getters['companies/extraUsersAddOns'].length;
    },

    userLicensesLabel() {
      if (!this.counts.users) {
        return '-/-';
      }

      const baseAllocation = this.$_guard_features.USER_ALLOCATION;
      const max = (baseAllocation * this.counts.locations) + (5 * this.userAddOns);

      return `${this.counts.users}/${max}`;
    },

    surveyPermissionStatusLabel() {
      if (this.company.partnerCanSendSurveys) {
        return 'Granted';
      }
      if (this.company.requestSurveyPermissionToken) {
        return 'Pending';
      }
      if (!this.company.partnerCanSendSurveys || this.company.partnerSurveyPermissionDenied) {
        return 'Not Granted';
      }
      return '';
    },
    location() {
      const locations = this.$store.getters['locations/locations'];
      if (locations.length) {
        return locations[0];
      }
      return null;
    },
  },

  methods: {
    upgradeSmsPack() {
      if (this.isLowJump) {
        bus.$emit('upgrade-company-intent', this.company);
      } else {
        this.smsPackModal = true;
      }
    },

    upgradeUsersPack() {
      if (this.isLowJump) {
        bus.$emit('upgrade-company-intent', this.company);
      } else {
        this.usersPackModal = true;
      }
    },

    openCancelAccountDialog() {
      bus.$emit('archive-company', this.company);
    },

    openPlanChangeDialog() {
      if (this.isLowJump) {
        return bus.$emit('upgrade-company', this.company);
      }

      return bus.$emit('downgrade-company', this.company);
    },

    openDashboard() {
      if (this.guard_partner_plan(codes.CERTIFIED_PARTNER_PLAN)) {
        return bus.$emit('elite-upgrade-cta');
      }

      localStorage.setItem('actAsCompanyId', this.company.id);

      const route = this.$router.resolve({
        name: 'company-home',
      });
      return window.open(route.href, '_blank');
    },

    openSurveyPermissionDialog() {
      this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'Request Survey Permission',
        text: `Would you like to request permission from ${this.company.name} to send surveys on their behalf?`,
        acceptText: 'Request Permission',
        accept: async() => {
          await this.requestSurveyPermission();
          const filters = httpBuildQuery({
            filter: JSON.stringify({
              include: [
                'plan',
                {
                  relation: 'companyPlans',
                  scope: {
                    where: {
                      active: true,
                    },
                  },
                },
                {
                  relation: 'locations',
                  scope: { where: { pending: false } },
                },
              ],
            }),
          });
          this.$store.dispatch('companies/getCompany', { companyId: this.company.id, filters });
        },
      });
    },

    async requestSurveyPermission() {
      const payload = {
        companyId: this.company.id,
        partnerUserId: this.partner.mainContactId,
      };
      await this.$store.dispatch('companies/sendSurveyPermissionRequest', payload);
      this.$vs.notify({ title: 'Success', text: 'Request for survey permission sent.', color: 'success' });
    },
  },
};
</script>
