<template>
  <vx-card title="Location Details">
    <form @submit.prevent="save">
      <!-- Location Name -->
      <vs-input
        v-model="form.name"
        label="Location Name"
        class="w-full"
      />

      <!-- Default Survey Template -->
      <div
        class="mt-3"
        @click="inputClicked('funnel')"
      >
        <label class="vs-input--label">Default Funnel</label>
        <rj-select
          v-model="form.defaultSurveyTemplateId"
          label="name"
          :reduce="funnel => funnel.id"
          :options="funnelsList"
          :clearable="false"
          class="w-full"
          :disabled="isLowJump"
          placeholder="Choose a default template..."
        />
      </div>

      <!-- Phone Number -->
      <div class="vx-row mt-3">
        <div class="vx-col w-full sm:w-1/2">
          <vs-input
            v-model="form.phone"
            label="Phone"
            class="w-full"
          />
        </div>

        <!-- Timezone -->
        <div class="vx-col w-full sm:w-1/2">
          <label class="vs-input--label">Timezone</label>
          <rj-select
            v-model="form.timezone"
            label="name"
            :reduce="timezone => timezone.name"
            :options="timezones"
            :clearable="false"
            class="w-full"
            placeholder="Choose a timezone..."
          />
        </div>
      </div>

      <div class="vx-row">
        <!-- Country -->
        <div class="vx-col w-full sm:w-1/2 mt-3">
          <label class="vs-input--label">Country</label>
          <rj-select
            v-model="form.addressCountry"
            :options="countries"
            label="countryName"
            :reduce="(country) => country.countryShortCode"
            :clearable="false"
            class="w-full"
            placeholder="Please select..."
          />
        </div>

        <!-- State -->
        <div class="vx-col w-full sm:w-1/2 mt-3">
          <label class="vs-input--label">State/Province/Region</label>
          <rj-select
            v-model="form.addressRegion"
            :options="regions"
            label="name"
            :reduce="(region) => region.shortCode"
            :clearable="false"
            class="w-full"
            placeholder="Please select..."
          />
        </div>
      </div>

      <!-- Street Address -->
      <vs-input
        v-model="form.addressStreet"
        class="w-full mt-3"
        label="Street Address"
      />

      <div class="vx-row">
        <!-- City -->
        <div class="vx-col sm:w-1/2 w-full mt-3">
          <vs-input
            v-model="form.addressCity"
            class="w-full"
            label="City"
          />
        </div>

        <!-- Zip Code -->
        <div class="vx-col sm:w-1/2 w-full mt-3">
          <vs-input
            v-model="form.addressZip"
            label="Zip/Postal Code"
            class="w-full"
          />
        </div>
      </div>

      <div
        v-if="!pristine"
        class="flex flex-wrap items-center justify-end mt-6"
      >
        <vs-button button="submit">
          Save
        </vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
import _debounce from 'lodash/debounce';
import { isPristine } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import { LOW_JUMP_CODE, HIGH_JUMP_CODE } from '@/rj_consts';

export default {
  name: 'RjLocationForm',

  components: { },

  mixins: [Authorizable],

  props: {
    location: {
      type: Object,
      required: true,
    },

    funnels: {
      type: Array,
      required: true,
    },

    timezones: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      pristine: true,
      form: {
        name: '',
        plan: {
          code: '',
        },
        defaultSurveyTemplateId: null,
        twilioPhone: '',
        timezone: '',
        phone: '',
        receiveCalls: false,
        addressCountry: '',
        addressState: '',
        addressStreet: '',
        addressCity: '',
        addressZip: '',
      },
      old: {},
    };
  },

  computed: {
    funnelsList() {
      if (this.isLowJump) {
        return this.funnels.filter((f) => f.type === 'Testimonial');
      }

      return this.funnels;
    },

    smsDisabled() {
      return this.guard_is('Company', (role) => /^Company/.test(role)) || this.isLowJump || this.location.twilioPhone;
    },

    countries() {
      return this.$store.getters['geography/countries'];
    },

    regions() {
      if (!this.form.addressCountry) {
        return [];
      }

      const country = this.countries.find((c) => c.countryShortCode === this.form.addressCountry);

      return country.regions;
    },

    surveyTemplates() {
      return [];
    },

    plans() {
      return (this.$store.getters['companies/plans'] || [])
        .filter((plan) => plan.code === HIGH_JUMP_CODE || plan.code === LOW_JUMP_CODE) // Restrict the locations to just high or low jump.
        .map((plan) => ({
          code: plan.code,
          id: plan.id,
          label: plan.name,
        }));
    },
  },

  watch: {
    'form': {
      deep: true,
      handler: 'hasChanges',
    },

    'form.addressCountry': function(country) {
      if (this.old.addressCountry === country) {
        this.form.addressRegion = this.old.addressRegion;
      } else {
        this.form.addressRegion = this.regions[0].code;
      }
    },
  },

  mounted() {
    this.$store.dispatch('companies/getPlans');
    this.defaults();
  },

  methods: {
    async save() {
      const changes = {};
      Object.keys(this.form).forEach((k) => {
        if (this.form[k] !== this.location[k]) {
          changes[k] = this.form[k];
        }
      });

      try {
        await this.$store.dispatch('locations/updateLocation', {
          payload: changes,
          locationId: this.location.id,
        });

        this.defaults();
        this.$vs.notify({ title: 'Success', text: 'Location saved.', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    defaults() {
      this.old = { ...this.location };
      this.form = { ...this.old };
      this.form.defaultSurveyTemplateId = this.form.defaultSurveyTemplateId || this.funnels?.[0]?.id;
    },

    inputClicked() {
      if (this.isLowJump) {
        this.$emit('should-upgrade');
      }
    },

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(updated) {
      this.pristine = isPristine(updated, this.old);
    }),
  },
};
</script>
