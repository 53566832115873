<template>
  <vs-prompt
    :title="title"
    :active="active"
    :buttons-hidden="pristine"
    accept-text="Save"
    class="rj-confirm-modal"
    @accept="submit"
    @close="close"
  >
    <form>
      <!-- Name -->
      <vs-input
        v-model="form.name"
        label="Name"
        class="w-full"
      />

      <!-- Google Place -->
      <vs-input
        v-model="form.googlePlaceId"
        label="Google Place ID"
        class="w-full mt-3"
      />
      <div class="flex justify-end">
        <a
          target="_blank"
          noreferrer
          class="text-sm"
          href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder"
        >PlaceId Finder</a>
      </div>
      <!-- Yelp Url -->
      <vs-input
        v-model="form.yelpUrl"
        label="Yelp URL"
        class="w-full mt-3"
      />
      <div class="flex justify-end">
        <a
          target="_blank"
          noreferrer
          class="text-sm"
          href="https://yelp.com"
        >Yelp.com</a>
      </div>
    </form>
  </vs-prompt>
</template>

<script>
import _debounce from 'lodash/debounce';
import { isPristine, isEmptyObject } from '@/utils';

export default {
  name: 'RjCreateCompetitor',

  props: {
    active: {
      type: Boolean,
      required: false,
    },

    competitor: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      pristine: true,
      form: {
        locationCompetitorId: null,
        name: '',
        googlePlaceId: '',
        yelpUrl: '',
      },
      old: {
        locationCompetitorId: null,
        name: '',
        googlePlaceId: '',
        yelpUrl: '',
      },
    };
  },

  computed: {
    action() {
      return this.hasCompetitor ? 'edit' : 'add';
    },

    hasCompetitor() {
      return !isEmptyObject(this.competitor);
    },

    title() {
      return this.action === 'add' ? 'Add Competitor' : 'Edit Competitor';
    },
  },

  watch: {
    form: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  created() {
    if (this.hasCompetitor) {
      const {
        id, name, googlePlaceId, yelpUrl,
      } = this.competitor;
      this.form = {
        locationCompetitorId: id,
        name,
        googlePlaceId,
        yelpUrl,
      };
      this.old = { ...this.form };
    }
  },

  methods: {
    submit() {
      this.$emit(`${this.action}-competitor`, this.form);
      this.close();
    },

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(original) {
      this.pristine = isPristine(original, this.old);
    }, 150),

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
