<template>
  <vs-prompt
    title="3-Pack"
    :active="active"
    :buttons-hidden="pristine"
    accept-text="Save"
    class="rj-confirm-modal"
    @accept="submit"
    @close="close"
  >
    <div>
      <p class="mb-3">
        These are the 3 sites that will be shown on review surveys.
      </p>

      <form class="space-y-3">
        <div
          v-for="i in 3"
          :key="i"
        >
          <label class="vs-input--label">Review Site #{{ i }}</label>
          <rj-select
            v-model="form.threePack[i - 1].id"
            :options="sortedLocationReviewSites"
            :reduce="site => site.id"
            placeholder="Choose a website..."
          />
        </div>
      </form>
    </div>
  </vs-prompt>
</template>

<script>
import _debounce from 'lodash/debounce';

export default {
  name: 'RjThreePack',

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    locationReviewSites: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      pristine: true,
      form: {
        threePack: [
          { priority: 1, id: null },
          { priority: 2, id: null },
          { priority: 3, id: null },
        ],
      },

      old: {
        threePack: [
          { priority: 1, id: null },
          { priority: 2, id: null },
          { priority: 3, id: null },
        ],
      },
    };
  },

  computed: {
    sortedLocationReviewSites() {
      const lrs = this.locationReviewSites.map((lrs) => ({ id: lrs.id, label: lrs.reviewSite.name }));

      return [...lrs].sort((curr, next) => (curr.label < next.label ? -1 : 1) || 0);
    },
  },

  watch: {
    form: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  created() {
    this.normalizeThreePack().forEach((site) => {
      this.form.threePack.splice((site.priority - 1), 1, { ...site });
      this.old.threePack.splice((site.priority - 1), 1, { ...site });
    });
  },

  methods: {
    submit() {
      const oldPack = this.old.threePack;
      const editedPack = this.form.threePack;

      if (this.hasDuplicates(editedPack)) {
        this.$vs.notify({ title: 'Error', text: 'Duplicate Review Site in 3-Pack', color: 'danger' });
        return;
      }

      const changes = editedPack.reduce((changes, site, index) => {
        // select for the first time
        if (site.id !== null && oldPack[index].id === null) {
          changes.push({ ...site });
        }

        // clear a selection
        if (site.id === null && oldPack[index].id !== null) {
          changes.push({ priority: null, id: oldPack[index].id });
        }

        // changed selection
        if (site.id !== null && site.id !== oldPack[index].id && oldPack[index].id !== null) {
          changes.push({ ...site }, { priority: null, id: oldPack[index].id });
        }

        return changes;
      }, []);

      this.$emit('edit-three-pack', changes);
      this.close();
    },

    normalizeThreePack() {
      return this.locationReviewSites
        .filter((site) => site.priority && site.priority <= 3)
        .map((site) => ({ priority: site.priority, id: site.id }));
    },

    hasDuplicates(editedPack) {
      const unique = new Set(editedPack.map((c) => c.id));

      return unique.size < editedPack.filter((e) => e.id !== null).length;
    },

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(original) {
      const editedIds = original.threePack.map((s) => s.id);
      const oldIds = this.old.threePack.map((s) => s.id);

      this.pristine = (JSON.stringify(editedIds) === JSON.stringify(oldIds));
    }, 150),

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
