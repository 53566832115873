<template>
  <div>
    <p class="text-sm text-center font-bold leading-tight">
      {{ funnel.reviewRequestHeading }}
    </p>
    <p class="text-xs leading-tight text-center mt-1">
      {{ funnel.reviewRequestContent }}
    </p>

    <p class="text-xs text-center font-bold leading-tight mt-3">
      Click to Review
    </p>

    <div class="pt-6 flex flex-col items-center">
      <ul class="w-full">
        <li
          v-for="site in threePack"
          :key="site.id"
          class="pl-2 py-2 bg-gray-200 my-2 rounded"
          style=""
        >
          <a
            href="#"
            @click.prevent="preview(site)"
          >
            <div class="flex items-center justify-between">
              <div class="flex items-center w-10/12">
                <img
                  :src="site.reviewSite.logo"
                  :alt="`Share on ${site.reviewSite.name}`"
                  class="w-8 h-8 inline-block"
                >
                <span class="pl-2 text-xs inline-block truncate">{{ site.reviewSite.name }}</span>
              </div>
              <div class="pt-1">
                <feather-icon
                  icon="ChevronRightIcon"
                  svg-classes=""
                />
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RjReviewSurveyPreview',

  props: {
    funnel: {
      type: Object,
      required: true,
    },
  },

  computed: {
    threePack() {
      return this.$store.getters['locations/threePack'].map((site) => {
        const logo = `${process.env.VUE_APP_RJ_REVIEW_SITE_LOGOS_URL}/${site.reviewSite.id}/${site.reviewSite.logo}`;
        return { ...site, reviewSite: { ...site.reviewSite, logo } };
      });
    },
  },

  methods: {
    preview(site) {
      window.open(site.newReviewUrl, '_blank');
    },
  },
};
</script>
