<template>
  <div class="flex flex-wrap -mx-4 mb-2">
    <!-- Reputation Performance  -->
    <div class="w-full sm:w-1/2 lg:w-1/4 px-4 mb-4">
      <statistics-card-line
        icon="StarIcon"
        :statistic="reputationPerformanceGrade.latest"
        statistic-title="Reputation Performance Grade"
        :chart-data="reputationPerformanceGrade.chartData"
        type="area"
        color="rjBlue"
      />
    </div>

    <!-- Competitor Review  -->
    <div class="w-full sm:w-1/2 lg:w-1/4 px-4 mb-4">
      <statistics-card-line
        icon="MapIcon"
        :statistic="competitorReviewScore.latest"
        statistic-title="Competitor Review Score"
        :chart-data="competitorReviewScore.chartData"
        type="area"
        color="rjOrange"
      />
    </div>

    <!-- Satisfaction Score -->
    <div class="w-full sm:w-1/2 lg:w-1/4 px-4 mb-4">
      <statistics-card-line
        icon="SmileIcon"
        :statistic="satisfactionScore.latest"
        statistic-title="Satisfaction Score"
        :chart-data="satisfactionScore.chartData"
        type="area"
        color="rjGreen"
      />
    </div>

    <!-- 30-Day Surveys -->
    <div class="w-full sm:w-1/2 lg:w-1/4 px-4 mb-4">
      <statistics-card-line
        icon="FileTextIcon"
        :statistic="thirtyDaySurveys.latest"
        statistic-title="30-Day Surveys"
        :chart-data="thirtyDaySurveys.chartData"
        type="area"
        color="rjRed"
      />
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';

export default {
  name: 'RjLocationStatistics',

  components: { StatisticsCardLine },

  computed: {
    location() {
      return this.$store.getters['locations/location'];
    },

    reputationPerformanceGrade() {
      const [lastItem] = this.location.reputationGrade.slice(-1);

      return {
        latest: lastItem.rpg,
        chartData: [{ name: 'RPG', data: this.location.reputationGrade.map((item) => item.rpg) }],
      };
    },

    competitorReviewScore() {
      return {
        latest: this.location.competitorReviewScore,
        chartData: [{ name: 'CRS', data: [] }],
      };
    },

    satisfactionScore() {
      return {
        latest: this.location.satisfactionScore,
        chartData: [{ name: 'SS', data: [] }],
      };
    },

    thirtyDaySurveys() {
      const [lastItem] = this.location?.thirtyDayCount.slice(-1);

      return {
        latest: lastItem.value,
        chartData: [{ name: '30-Day Surveys', data: this.location.thirtyDayCount.map((item) => item.value) }],
      };
    },
  },
};
</script>
