<!--
 * File Name: TheFooter.vue
 * Description: Footer component
 * Component Name: TheFooter
 * Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
 * Author: Pixinvent
 * Author URL: http://www.themeforest.net/user/pixinvent
-->

<template functional>
  <footer
    class="the-footer flex-wrap justify-between"
    :class="classes"
  >
    <p>
      &copy;
      {{ new Date().getFullYear() }}
      <a
        href="https://www.revenuejump.com/"
        target="_blank"
        rel="nofollow"
      >
        RevenueJump.
      </a>
      All rights reserved.
    </p>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
};
</script>
