<template>
  <table class="w-full">
    <tr>
      <td class="font-semibold">
        Plan
      </td>
      <td>
        {{ plan.name }}
      </td>
      <td>
        <vs-button
          v-if="isLowJump"
          type="flat"
          icon-pack="feather"
          class="inline-block"
          icon="icon-arrow-up"
          title="Upgrade Plan"
          @click.stop="upgrade"
        />
        <button
          v-else
          style="visibility: hidden; width: 32px; height: 32px;"
        />
      </td>
    </tr>

    <tr>
      <td class="font-semibold">
        Status
      </td>
      <td>
        {{ company.active ? 'Active' : 'Inactive' }}
      </td>
      <td>
        <button style="visibility: hidden; width: 32px; height: 32px;" />
      </td>
    </tr>

    <tr>
      <td class="font-semibold">
        SMS Credits
      </td>
      <td>
        {{ smsCreditsLabel }}
      </td>
      <td>
        <vs-button
          icon-pack="feather"
          icon="icon-arrow-up"
          type="flat"
          class="inline-block"
          title="Upgrade SMS Credits"
          @click.stop="upgradeSmsPack"
        />
      </td>
    </tr>

    <tr>
      <td class="font-semibold">
        User Licenses
      </td>
      <td>
        {{ userLicensesLabel }}
      </td>
      <td>
        <vs-button
          icon-pack="feather"
          icon="icon-arrow-up"
          type="flat"
          class="inline-block"
          title="Upgrade Users"
          @click.stop="upgradeUsersPack"
        />
      </td>
    </tr>

    <tr>
      <td class="font-semibold">
        Partner Survey Permissions
      </td>
      <td>
        <vs-switch v-model="permission">
          <span slot="on">YES</span>
          <span slot="off">NO</span>
        </vs-switch>
      </td>
      <td>
        <button style="visibility: hidden; width: 32px; height: 32px;" />
      </td>
    </tr>
  </table>
</template>

<script>
import { bus } from '@/utils';
import Authorizable from '@/mixins/Authorizable';

export default {
  name: 'RjCompanyOverviewPlan',

  mixins: [Authorizable],

  props: {
    company: {
      type: Object,
      required: true,
    },

    plan: {
      type: Object,
      required: true,
    },

    partner: {
      type: Object,
      required: true,
    },

    counts: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      permission: this.company.partnerCanSendSurveys,
    };
  },

  computed: {
    smsCreditsLabel() {
      return `${this.counts.sms.used}/${this.counts.sms.max}`;
    },

    userAddOns() {
      return this.$store.getters['companies/extraUsersAddOns'].length;
    },

    userLicensesLabel() {
      if (!this.counts.users) {
        return '-/-';
      }

      const baseAllocation = this.$_guard_features.USER_ALLOCATION;
      const max = (baseAllocation * this.counts.locations) + (5 * this.userAddOns);

      return `${this.counts.users}/${max}`;
    },
  },

  watch: {
    permission: {
      handler: 'toggleSurveysPermissions',
    },
  },

  methods: {
    upgradeSmsPack() {
      if (this.isLowJump) {
        return this.upgrade();
      }

      return this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'Add SMS Credits',
        text: `Click the button below to request that ${this.partner.name} add SMS credits to your RevenueJump account.`,
        acceptText: 'Send Request',
        accept: () => console.log('Send Email...'),
      });
    },

    upgradeUsersPack() {
      if (this.isLowJump) {
        return this.upgrade();
      }

      return this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'Add User Licenses',
        text: `Click the button below to request that ${this.partner.name} add user licenses to your RevenueJump account.`,
        acceptText: 'Send Request',
        accept: () => console.log('Send Email...'),
      });
    },

    async toggleSurveysPermissions(permission) {
      await this.$store.dispatch('companies/updateCompanyDetails', {
        companyId: this.company.id,
        payload: { partnerCanSendSurveys: permission },
      });
    },

    upgrade() {
      bus.$emit('upgrade-company');
    },
  },
};
</script>
