<template>
  <rj-phone-previewer>
    <img
      :src="require('@/assets/images/phone/web-browser-top.png')"
      :srcset="require('@/assets/images/phone/web-browser-top.png') + ' 1x, ' + require('@/assets/images/phone/web-browser-top@2x.png') + ' 2x'"
      alt="Safari Navigaiton Bar"
      class="iphone__keyboard"
    >

    <div class="smartphone__browser-screen">
      <slot />
    </div>

    <img
      :src="require('@/assets/images/phone/web-browser-bottom.png')"
      :srcset="require('@/assets/images/phone/web-browser-bottom.png') + ' 1x, ' + require('@/assets/images/phone/web-browser-bottom@2x.png') + ' 2x'"
      alt="Safari Toolbar"
      class="iphone__keyboard"
    >
  </rj-phone-previewer>
</template>

<script>
import RjPhonePreviewer from '@/views/common/funnels/smartphone/RjPhonePreviewer.vue';

export default {
  name: 'RjPhoneBrowserScreen',

  components: { RjPhonePreviewer },
};
</script>
