<template>
  <div>
    <div v-if="!feedback">
      <p class="text-sm text-center font-bold leading-tight">
        {{ funnel.surveyQuestionYesResponse }}
      </p>

      <vs-textarea
        height="54px"
        class="mb-0 p-2 text-xs mt-3"
        placeholder="Your feedback will be very helpful..."
      />

      <div class="flex justify-center mt-3">
        <vs-button
          size="small"
          color="success"
          class="px-4 py-2"
          @click="feedback = true"
        >
          Send
        </vs-button>
      </div>
    </div>

    <div v-else>
      <p class="text-sm text-center font-bold leading-tight">
        {{ funnel.photoRequestHeading }}
      </p>
      <p class="text-xs leading-tight text-center mt-2">
        {{ funnel.photoRequestContent }}
      </p>

      <div
        class="relative h-16 w-16 flex items-center justify-center rounded-full mx-auto overflow-hidden mt-4"
        :class="{ 'bg-primary': !avatar }"
      >
        <img
          :src="avatar || dummy"
          class="object-cover"
          :class="{ 'h-16 w-16 rounded-full': avatar, 'w-8': !avatar }"
        >
        <input
          ref="uploader"
          type="file"
          class="hidden"
          @change="preview"
        >
      </div>

      <div class="flex justify-center space-x-4 mt-5">
        <template v-if="!hasAvatar">
          <vs-button
            :color="colors.rjGray500"
            :text-color="colors.rjGray500"
            type="border"
            size="small"
            class="px-4 py-2"
            @click="$emit('completed')"
          >
            No
          </vs-button>
          <vs-button
            color="success"
            size="small"
            class="px-4 py-2"
            @click="browse"
          >
            Upload
          </vs-button>
        </template>

        <template v-else>
          <vs-button
            :color="colors.rjGray500"
            :text-color="colors.rjGray500"
            type="border"
            size="small"
            class="px-4 py-2"
            @click="browse"
          >
            Re-Upload
          </vs-button>
          <vs-button
            color="success"
            size="small"
            class="px-4 py-2"
            @click="$emit('completed')"
          >
            Save
          </vs-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { colors } from '@/../themeConfig';

export default {
  name: 'RjTestimonialSurveyPreview',

  props: {
    funnel: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      avatar: null,
      file: null,
      feedback: null,
    };
  },

  computed: {
    colors() {
      return colors;
    },

    hasAvatar() {
      return this.avatar !== null;
    },

    dummy() {
      return require('@/assets/images/pages/default-avatar.png');
    },
  },

  methods: {
    async preview() {
      await this.$nextTick();

      [this.file] = this.$refs.uploader.files;
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.avatar = reader.result;
      });

      reader.readAsDataURL(this.file);
    },

    browse() {
      this.$refs.uploader.click();
    },
  },
};
</script>
