<template>
  <section>
    <div class="flex flex-wrap -mx-4">
      <div class="w-full flex sm:w-1/2 px-4 mb-4">
        <vx-card title="Signature Details">
          <form>
            <!-- Signature Line 1 -->
            <vs-input
              v-model="form.emailSignatureOne"
              label="Signature Line 1"
              class="w-full"
            />

            <!-- Signature Line 2 -->
            <vs-input
              v-model="form.emailSignatureTwo"
              label="Signature Line 2"
              class="w-full mt-3"
            />

            <!-- Signature Line 3 -->
            <vs-input
              v-model="form.emailSignatureThree"
              label="Signature Line 3"
              class="w-full mt-3"
            />

            <!-- Signature Line 4 -->
            <vs-input
              v-model="form.emailSignatureFour"
              label="Signature Line 4"
              class="w-full mt-3"
            />

            <div class="flex flex-wrap items-center justify-end mt-6">
              <vs-button
                v-if="!pristine"
                type="border"
                @click.prevent="reset"
              >
                Reset
              </vs-button>

              <vs-button
                v-if="!pristine"
                class="ml-3"
                @click.prevent="submit"
              >
                Save
              </vs-button>
            </div>
          </form>
        </vx-card>
      </div>

      <div class="w-full flex sm:w-1/2 px-4 mb-4">
        <vx-card title="Signature Preview">
          <img
            v-if="logo"
            :src="logo"
            alt="Company Logo"
            class="w-32 mb-1 mt-5"
          >
          <p class="font-bold">
            {{ form.emailSignatureOne }}
          </p>
          <p>{{ form.emailSignatureTwo }}</p>
          <p>{{ form.emailSignatureThree }}</p>
          <p>{{ form.emailSignatureFour }}</p>
        </vx-card>
      </div>
    </div>
  </section>
</template>

<script>
import _debounce from 'lodash/debounce';
import { isPristine, hasKey } from '@/utils';

export default {
  name: 'RjEmailSignature',

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pristine: true,
      form: {
        emailSignatureOne: '',
        emailSignatureTwo: '',
        emailSignatureThree: '',
        emailSignatureFour: '',
      },
      old: {},
    };
  },

  computed: {
    logo() {
      const { id, logo } = this.company;
      if (logo && /https?:\/\//.test(logo)) {
        return logo;
      }
      if (logo) {
        return `${process.env.VUE_APP_RJ_COMPANY_LOGO_URL}/${id}/${logo}`;
      }
      return null;
    },
  },

  watch: {
    form: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  created() {
    this.buildFormObject();
    this.old = { ...this.form };
  },

  methods: {
    /**
     * Updates the email signature.
     *
     * @return {void}
     */
    async submit() {
      await this.$store.dispatch('companies/updateEmailSignature', {
        payload: this.form,
        companyId: this.company.id,
      });

      this.old = { ...this.form };
      this.pristine = true;
    },

    /**
     * Hydrates the form with a current email signature
     *
     * @return {void}
     */
    buildFormObject() {
      for (const key in this.form) {
        if (hasKey(this.company, key) && hasKey(this.form, key)) {
          this.form[key] = this.company[key];
        }
      }
    },

    /**
     * Reset the signature to its initial state.
     *
     * @return {void}
     */
    reset() {
      this.form = { ...this.old };
    },

    /**
     * Checks if the form has been changed.
     *
     * @param  {Object} original
     * @return {void}
     */
    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(original) {
      this.pristine = isPristine(original, this.old);
    }, 150),
  },
};
</script>
