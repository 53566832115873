<template>
  <vs-table
    ref="table"
    pagination
    search
    :data="funnels"
    :max-items="perPage"
    :no-data-text="'No Funnels'"
    @selected="(funnel) => $emit('selected', funnel)"
  >
    <template #header>
      <div class="flex items-center flex-grow justify-between">
        <div class="flex items-center data-list-btn-container">
          <slot name="actions" />
        </div>

        <slot name="filters">
          <div class="cursor-pointer mr-4 items-per-page-handler">
            <vs-dropdown vs-trigger-click>
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">
                  {{ currentPage * perPage - (perPage - 1) }}
                  -
                  {{ funnels.length - currentPage * perPage > 0 ? currentPage * perPage : funnels.length }}
                  of
                  {{ queriedItems }}
                </span>
                <feather-icon
                  icon="ChevronDownIcon"
                  svg-classes="h-4 w-4"
                />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item @click="perPage = 4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="perPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="perPage = 15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="perPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </slot>
      </div>
    </template>

    <template #thead>
      <slot name="table-head">
        <vs-th
          sort-key="name"
          class="th__funnels-name"
        >
          FUNNEL NAME
        </vs-th>
        <vs-th
          sort-key="type"
          class="th__funnels-type"
        >
          <span class="w-full text-center">FUNNEL GOAL</span>
        </vs-th>
        <vs-th
          sort-key="surveys_sent"
          class="th__funnels-surveys"
        >
          <span class="w-full text-center">30-DAY SURVEYS</span>
        </vs-th>
        <vs-th
          sort-key="visit_rate"
          class="th__funnels-progress"
        >
          <span class="w-full text-center">VISIT RATE</span>
        </vs-th>
        <vs-th
          sort-key="completion_date"
          class="th__funnels-progress"
        >
          <span class="w-full text-center">COMPLETION RATE</span>
        </vs-th>
        <vs-th
          sort-key="statisfaction_score"
          class="th__funnels-progress"
        >
          <span class="w-full text-center">SATISFACTION SCORE</span>
        </vs-th>
        <vs-th class="th__funnels-action">
          <span class="w-full text-center">ACTION</span>
        </vs-th>
      </slot>
    </template>

    <template #default="{ data }">
      <tbody>
        <vs-tr
          v-for="(funnel, index) in data"
          :key="index"
          :data="funnel"
          :class="{ 'bg-gray-200': !funnel.active }"
        >
          <slot
            name="table-row"
            :funnel="funnel"
          />
        </vs-tr>
      </tbody>
    </template>
  </vs-table>
</template>

<script>
export default {
  name: 'RjFunnelsList',

  props: {
    funnels: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      perPage: 15,
      isMounted: false,
    };
  },

  computed: {
    currentPage() {
      return this.isMounted ? this.$refs.table.currentx : 0;
    },

    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.funnels.length;
    },
  },

  mounted() {
    this.isMounted = true;
  },
};
</script>
