<template>
  <div class="vx-row mt-3">
    <form class="vx-col w-full sm:w-7/12">
      <div class="vx-row">
        <div class="vx-col w-full sm:w-1/2">
          <!-- Message Type -->
          <label class="vs-input--label">Message Type</label>
          <rj-select
            :value="form.type"
            :options="types"
            :reduce="type => type.type"
            :clearable="false"
            :disabled="isCompanyUser"
            placeholder="Choose a website..."
            @input="(value) => setFormValue('type', value)"
          />
        </div>

        <div class="vx-col w-full sm:w-1/2">
          <!-- Message Delay -->
          <div class="w-full">
            <label class="vs-input--label">Message Delay</label>
            <rj-select
              :value="form.delay"
              :options="delays"
              :reduce="delay => delay.value"
              :clearable="false"
              placeholder="Choose a delay..."
              @input="(value) => setFormValue('delay', value)"
            />
          </div>
        </div>
      </div>

      <template v-if="!isEmailOnly">
        <!-- Text Message -->
        <label class="vs-input--label block mt-3">Text Message</label>
        <vs-textarea
          v-validate="'survey_message'"
          name="textBody"
          :value="form.textBody"
          height="8rem"
          class="w-full p-3 mb-0"
          :class="{ 'vs--disabled': isCompanyUser }"
          :disabled="isCompanyUser"
          @input="(value) => setFormValue('textBody', value)"
        />
        <span class="text-sm text-red-500">
          {{ errors.first('textBody') }}
        </span>
      </template>

      <template v-if="!isTextOnly">
        <!-- Email Subject -->
        <vs-input
          :value="form.emailSubject"
          label="Email Subject"
          :disabled="isCompanyUser"
          class="w-full mt-3"
          @input="(value) => setFormValue('emailSubject', value)"
        />

        <!-- Email Body -->
        <div class="mt-3">
          <label class="vs-input--label">Email Body</label>
          <vs-textarea
            v-validate="'survey_message'"
            name="emailBody"
            :value="form.emailBody"
            height="10rem"
            class="w-full p-3"
            :disabled="isCompanyUser"
            :class="{ 'vs--disabled': isCompanyUser }"
            @input="(value) => setFormValue('emailBody', value)"
          />
          <span class="text-sm text-red-500">
            {{ errors.first('emailBody') }}
          </span>
        </div>
      </template>

      <div class="vx-row mt-4">
        <div class="vx-col w-full sm:w-1/2">
          <div class="text-sm leading-snug mb-3">
            <p class="font-bold">
              Merge Fields
            </p>
            <p>First Name: &lt;FirstName&gt;</p>
            <p>Last Name: &lt;LastName&gt;</p>
            <p>Survey Link: &lt;SurveyLink&gt;</p>
          </div>
        </div>
      </div>
    </form>

    <!-- Message Preview -->
    <div class="vx-col w-full sm:w-5/12">
      <div
        ref="swiper"
        class="swiper-container"
      >
        <div class="swiper-wrapper">
          <template v-if="!isEmailOnly">
            <div class="swiper-slide">
              <rj-phone-message-screen :sms="sampleSmsMessage" />
            </div>
          </template>

          <template v-if="!isTextOnly">
            <div class="swiper-slide">
              <rj-phone-email-screen
                :message="sampleEmailMessage"
                :survey="sampleSurvey"
              />
            </div>
          </template>
        </div>
      </div>

      <template v-if="isTextAndEmail">
        <rj-phone-switch-button
          :slider="swiper"
          :selected="tab"
          @slide-to="(index) => tab = index"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import _debounce from 'lodash/debounce';
import { isPristine, hasKey } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import RjPhoneEmailScreen from '@/views/common/funnels/smartphone/RjPhoneEmailScreen.vue';
import RjPhoneMessageScreen from '@/views/common/funnels/smartphone/RjPhoneMessageScreen.vue';
import RjPhoneSwitchButton from '@/views/common/funnels/partials/RjPhoneSwitchButton.vue';

export default {
  name: 'RjFunnelMessageForm',

  components: {
    RjPhoneEmailScreen,
    RjPhoneMessageScreen,
    RjPhoneSwitchButton,
  },

  mixins: [Authorizable],

  props: {
    index: {
      type: Number,
      required: true,
    },

    funnel: {
      type: Object,
      required: true,
    },

    isCompanyUser: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      tab: 0,
      swiper: null,
      pristine: true,
      old: {},
      form: {
        type: '',
        delay: '',
        textBody: '',
        emailBody: '',
        emailSubject: '',
      },
    };
  },

  computed: {
    types() {
      if (this.disableSms) {
        return [{ type: 'Email', label: 'Email' }];
      }

      return [
        { type: 'Text', label: 'Text' },
        { type: 'Email', label: 'Email' },
        { type: 'TextEmail', label: 'Text + Email' },
      ];
    },

    message() {
      return {
        type: {
          key: `message${this.index}PreferredType`,
          value: this.disableSms ? 'Email' : this.funnel[`message${this.index}PreferredType`],
        },
        delay: {
          key: `message${this.index}Delay`,
          value: this.funnel[`message${this.index}Delay`],
        },
        textBody: {
          key: `message${this.index}TextBody`,
          value: this.funnel[`message${this.index}TextBody`],
        },
        emailBody: {
          key: `message${this.index}EmailBody`,
          value: this.funnel[`message${this.index}EmailBody`],
        },
        emailSubject: {
          key: `message${this.index}EmailSubject`,
          value: this.funnel[`message${this.index}EmailSubject`],
        },
      };
    },

    sampleSurvey() {
      return {
        surveyTemplate: this.funnel,
        contact: {
          firstName: 'David',
          lastName: 'Coleman',
        },
      };
    },

    sampleEmailMessage() {
      let emailBody = this.message.emailBody.value;
      emailBody = emailBody.replace('<SurveyLink>', '<a href="#">https://ihelp.bz/ab98dj</a>');
      emailBody = emailBody.replace('<FirstName>', 'John');
      emailBody = emailBody.replace('<LastName>', 'Doe');

      return {
        text: `
        ${emailBody}<br><br>
        <div style="display: flex; flex-direction: column;">
          <div style="margin-top:auto;margin-bottom:auto;">
            <table style="line-height:95%">
              <tbody>
                <tr>
                  <td><strong>${this.company.emailSignatureOne}</strong></td>
                </tr>
                <tr>
                  <td>${this.company.emailSignatureTwo}</td>
                </tr>
                <tr>
                  <td>${this.company.emailSignatureThree}</td>
                </tr>
                <tr>
                  <td>${this.company.emailSignatureFour}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="padding-right: 1em;">
            <img src="${this.companyLogoUrl}" height="65">
          </div>
        </div><br><br><br>
        <p style="color: grey;font-size: 0.8em;">Thank you for allowing us to send you this survey email.  <a href="#">Unsubscribe</a> if you'd like to change this preference.</p>`,
      };
    },

    sampleSmsMessage() {
      let message = this.message.textBody.value;
      message = message.replace('<SurveyLink>', 'https://ihelp.bz/ab98dj');
      message = message.replace('<FirstName>', 'John');
      message = message.replace('<LastName>', 'Doe');

      return message;
    },

    company() {
      return this.$store.getters['companies/company'];
    },

    locations() {
      return this.$store.getters['locations/locations'];
    },

    disableSms() {
      return this.isLowJump;
    },

    companyLogoUrl() {
      const { id, logo } = this.company;
      if (logo && /https?:\/\//.test(logo)) {
        return logo;
      }
      if (logo) {
        return `${process.env.VUE_APP_RJ_COMPANY_LOGO_URL}/${id}/${logo}`;
      }
      return null;
    },

    isTextOnly() {
      return this.form.type === 'Text';
    },

    isEmailOnly() {
      return this.form.type === 'Email';
    },

    isTextAndEmail() {
      return this.form.type === 'TextEmail';
    },

    delays() {
      const options = [
        { value: 0, label: 'Immediate' },
        { value: 1800, label: '30 minutes' },
        { value: 86400, label: '1 day' },
        { value: 172800, label: '2 days' },
        { value: 259200, label: '3 days' },
        { value: 432000, label: '5 days' },
        { value: 604800, label: '1 week' },
        { value: 1209600, label: '2 weeks' },
        { value: 2629743, label: '1 month' },
        { value: 5259487, label: '2 months' },
        { value: 7889231, label: '3 months' },
        { value: 15778463, label: '6 months' },
        { value: 31536000, label: '1 year' },
      ];

      if (!this.isTextOnly) {
        options.unshift({ value: null, label: 'Do Not Send' });
      }

      return options;
    },
  },

  watch: {
    form: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  async mounted() {
    await this.$nextTick();
    this.buildFormObject();
    this.swiper = new Swiper(this.$refs.swiper);
    this.$validator.extend('survey_message', {
      getMessage: () => 'The message must contain a survey link in this format: <SurveyLink>',
      validate: (value) => /<SurveyLink>/.test(value),
    });
  },

  beforeDestroy() {
    this.swiper.destroy();
    this.swiper = null;
  },

  methods: {
    buildFormObject() {
      for (const key in this.message) {
        if (hasKey(this.message, key) && hasKey(this.form, key)) {
          this.form[key] = this.message[key].value;
        }
      }

      this.old = { ...this.form };
    },

    /**
     * Reset the form to its original state.
     *
     * @return {void}
     */
    reset() {
      this.form = { ...this.old };

      const message = Object.keys(this.message).reduce((obj, key) => ({
        ...obj, [this.message[key].key]: this.old[key],
      }), {});
      this.$store.commit('funnels/SET_FUNNEL', { ...this.funnel, ...message });
    },

    async setFormValue(prop, value) {
      this.form[prop] = value;
      await this.$nextTick();
      this.$validator.validateAll().then((result) => {
        this.$store.commit('funnels/SET_FUNNEL_VALID', result);
      });
      const { key } = this.message[prop];
      this.$store.commit('funnels/UPDATE_FUNNEL_PROP', { prop: key, value });
    },

    /**
     * Checks if the form has been changed.
     *
     * @param  {Object} original
     * @return {void}
     */
    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(current) {
      this.pristine = isPristine(current, this.old);
    }, 180),
  },
};
</script>
