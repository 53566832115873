<template functional>
  <section class="flex items-center justify-center w-full">
    <div class="smartphone__cover">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'RjPhonePreviewer',
};
</script>
