<template>
  <div
    class="rounded-lg w-48 text-gray-600 flex mx-auto overflow-hidden shadow mt-3"
    :style="{ 'background-color': colors.lightPurple }"
  >
    <button
      class="px-3 py-2 outline-none flex items-center justify-center flex-grow"
      :class="{ 'w-1/2 rounded-lg shadow-md text-white': selected === 0 }"
      :style="{ 'background-color': selected === 0 ? colors.darkPurple : colors.lightPurple }"
      @click="slideTo(0)"
    >
      <feather-icon
        icon="MessageSquareIcon"
        svg-classes="w-4 h-4"
      />
      <span class="ml-2 text-sm">Text</span>
    </button>

    <button
      class="px-3 py-2 outline-none flex items-center justify-center flex-grow w-1/2"
      :class="{ 'w-1/2 rounded-lg shadow-md text-white': selected === 1 }"
      :style="{ 'background-color': selected === 1 ? colors.darkPurple : colors.lightPurple }"
      @click="slideTo(1)"
    >
      <feather-icon
        icon="MailIcon"
        svg-classes="w-4 h-4"
      />
      <span class="ml-2 text-sm">Email</span>
    </button>
  </div>
</template>

<script>
import Color from 'color';
import { colors as themeColors } from '@/../themeConfig';

export default {
  name: 'RjPhoneSwitchButton',

  props: {
    selected: {
      type: Number,
      required: true,
      default: 0,
    },

    slider: {
      type: null,
      required: true,
    },
  },

  computed: {
    colors() {
      const { rjPurple } = themeColors;

      return {
        darkPurple: rjPurple,
        lightPurple: Color(rjPurple).lightness(93).hex(),
      };
    },
  },

  methods: {
    slideTo(index) {
      this.$emit('slide-to', index);
      this.slider.slideTo(index, 300, false);
    },
  },
};
</script>
