<template>
  <div>
    <vx-card title="Competitors">
      <template #actions>
        <vs-button @click="toggleCompetitorModal({})">
          Add Competitor
        </vs-button>
      </template>

      <template #no-body>
        <vs-table
          class="mt-6"
          :data="competitors"
          :no-data-text="'No Competitors'"
          @selected="toggleCompetitorModal"
        >
          <template #thead>
            <vs-th>
              <span class="uppercase w-full">Name</span>
            </vs-th>
            <vs-th>
              <span class="uppercase w-full text-center">Reviews</span>
            </vs-th>
            <vs-th>
              <span class="uppercase w-full text-center">Rating</span>
            </vs-th>
            <vs-th>
              <span class="uppercase w-full text-center">Review Sites</span>
            </vs-th>
            <vs-th>
              <span class="uppercase w-full text-center">Action</span>
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              v-for="(competitor, index) in data"
              :key="index"
              :data="competitor"
            >
              <vs-td>
                <p class="font-medium truncate">
                  {{ competitor.name }}
                </p>
              </vs-td>
              <vs-td>
                <div class="w-full flex justify-center">
                  {{ competitor.reviewCount }}
                </div>
              </vs-td>
              <vs-td>
                <div class="w-full flex justify-center">
                  <star-rating
                    border-color="#d8d8d8"
                    :rating="competitor.rating"
                    :border-width="3"
                    :rounded-corners="true"
                    :star-size="14"
                    :show-rating="false"
                    :read-only="true"
                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                  />
                </div>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <div class="flex items-center justify-center">
                  <img
                    v-for="site in competitor.reviewSites"
                    :key="site.id"
                    :src="`${VUE_APP_RJ_REVIEW_SITE_LOGOS_URL}/${site.id}/${site.logo}`"
                    :alt="site.name"
                    class="mr-2 w-6"
                  >
                </div>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <div class="w-full flex justify-center">
                  <vs-button
                    icon-pack="feather"
                    icon="icon-settings"
                    type="flat"
                    class="text-rjPurple inline-block"
                    title="Edit Competitor"
                    @click.stop="toggleCompetitorModal(competitor)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </vx-card>

    <rj-competitor-dialog
      v-if="competitorModal.active"
      :active.sync="competitorModal.active"
      :competitor="competitorModal.competitor"
      @add-competitor="onAddCompetitor"
      @edit-competitor="onEditCompetitor"
    />
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';
import Authorizable from '@/mixins/Authorizable';
import { GOOGLE_REVIEW_SITE_CODE, YELP_REVIEW_SITE_CODE } from '@/rj_consts';
import RjCompetitorDialog from '@/views/common/locations/modals/RjCompetitorDialog.vue';

const { VUE_APP_RJ_REVIEW_SITE_LOGOS_URL } = process.env;

export default {
  name: 'RjCompetitors',

  components: {
    StarRating,
    RjCompetitorDialog,
  },

  mixins: [Authorizable],

  props: {
    location: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      GOOGLE_REVIEW_SITE_CODE,
      YELP_REVIEW_SITE_CODE,
      VUE_APP_RJ_REVIEW_SITE_LOGOS_URL,
      competitorModal: {
        active: false,
        competitor: {},
      },
      modal: false,
      selected: {},
    };
  },

  computed: {
    reviewSites() {
      return this.$store.getters['revenuejump/reviewSites'] || [];
    },

    competitors() {
      return this.$store.getters['locations/competitors'].map((lc) => {
        // Get total number of reviews.
        let numReviews = 0;
        if (lc.googleReviewCount !== null) {
          numReviews += lc.googleReviewCount;
        }
        if (lc.yelpReviewCount !== null) {
          numReviews += lc.yelpReviewCount;
        }
        // Get average rating.
        let ratingSum = 0;
        let numRatingsCompared = 0;
        if (lc.googleRating !== null) {
          ratingSum += lc.googleRating;
          numRatingsCompared++;
        }
        if (lc.yelpRating !== null) {
          ratingSum += lc.yelpRating;
          numRatingsCompared++;
        }

        // Get review sites.
        const reviewSites = [];
        if (lc.googleUrl !== null) {
          reviewSites.push(this.reviewSites.find((rs) => rs.code === this.GOOGLE_REVIEW_SITE_CODE));
        }
        if (lc.yelpUrl !== null) {
          reviewSites.push(this.reviewSites.find((rs) => rs.code === this.YELP_REVIEW_SITE_CODE));
        }
        return {
          ...lc,
          rating: ratingSum / numRatingsCompared,
          reviewCount: numReviews,
          reviewSites,
        };
      });
    },
  },

  methods: {
    toggleCompetitorModal(competitor = {}) {
      this.competitorModal.competitor = competitor;
      this.competitorModal.active = !this.competitorModal.active;
    },

    async onAddCompetitor(competitor) {
      try {
        await this.$store.dispatch('locations/addCompetitor', {
          payload: competitor,
          locationId: this.location.id,
        });

        this.$vs.notify({ title: 'Success', text: 'Competitor added.', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async onEditCompetitor(competitor) {
      try {
        await this.$store.dispatch('locations/editCompetitor', competitor);
        this.$vs.notify({ title: 'Success', text: 'Competitor saved.', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },
  },
};
</script>
