<!--
 * File Name: StatisticsCard.vue
 * Description: Statistics card component
 * Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
 * Author: Pixinvent
 * Author URL: http://www.themeforest.net/user/pixinvent
-->

<template>
  <vx-card
    class="overflow-hidden"
    v-on="$listeners"
  >
    <div slot="no-body">
      <div
        class="p-6"
        :class="{
          'flex justify-between flex-row-reverse items-center': iconRight,
          'text-center': !iconRight && hideChart,
          'pb-0': !hideChart
        }"
      >
        <div
          class="flex items-center justify-between"
          :class="{'mb-4': !iconRight}"
        >
          <feather-icon
            :icon="icon"
            class="p-3 inline-flex rounded-full"
            :style="{
              color: themeColors[color],
              'background-color': Color(themeColors[color]).lightness(93).hex(),
            }"
          />

          <div>
            <slot name="actions" />
          </div>
        </div>
        <div
          class="truncate"
        >
          <h2
            class="mb-1 font-bold"
          >
            {{ statistic }}
          </h2>
          <span>{{ statisticTitle }}</span>
        </div>
      </div>

      <div
        v-if="!hideChart"
        class="line-area-chart"
      >
        <vue-apex-charts
          ref="apexChart"
          :type="type"
          height="100"
          width="100%"
          :options="chartOptions"
          :series="chartData"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import Color from 'color';
import { colors as themeColors } from '@/../themeConfig';
import chartConfigs from './chartConfigs';

export default {
  components: {
    VueApexCharts,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: 'primary',
    },
    colorTo: {
      type: String,
      default: '',
    },
    // chartType: {
    //     type: String,
    //     default: 'line-chart',
    // },
    type: {
      type: String,
      default: 'line',
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    hideChart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartOptions: null,
      themeColors,
      Color,
    };
  },
  computed: {
    themePrimaryColor() {
      return this.$store.state.themePrimaryColor;
    },
  },
  watch: {
    themePrimaryColor() {
      this.$refs.apexChart.updateOptions({ theme: { monochrome: { color: this.themeColors[this.color] } } });
    },
  },
  created() {
    if (this.type === 'area') {
      // Assign chart options
      this.chartOptions = { ...chartConfigs.areaChartOptions };

      this.chartOptions.theme = {
        monochrome: {
          enabled: true,
          color: this.themeColors[this.color],
          shadeTo: 'light',
          shadeIntensity: 0.65,
        },
      };
    } else if (this.type === 'line') {
      // Assign chart options
      this.chartOptions = JSON.parse(JSON.stringify(chartConfigs.lineChartOptions));

      this.chartOptions.fill.gradient.gradientToColors = [this.gradientToColor(this.colorTo || this.color)];
      this.chartOptions.colors = [this.themeColors[this.color]];
    }
  },
  methods: {
    gradientToColor(color) {
      const gradientToColors = {
        primary: '#A9A2F6',
        success: '#55DD92',
        warning: '#ffc085',
        danger: '#F97794',
        rjBlue: '#3dc9b3',
      };

      return gradientToColors[color] ? gradientToColors[color] : gradientToColors.primary;
    },
  },
};
</script>
