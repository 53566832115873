<template>
  <div>
    <div
      class="relative bg-gray-200 border border-gray-400 border-dashed rounded-lg hover:border-gray-500 overflow-hidden mx-auto"
      style="max-width: 256px; height: 140px;"
    >
      <template v-if="logo">
        <div class="relative h-full flex flex-col justify-center items-center cursor-pointer">
          <button
            class="absolute w-8 h-8 flex justify-center items-center text-white rounded-full bg-black top-0 right-0 mt-2 mr-2 bg-opacity-25"
            @click="$emit('file-uploaded', null)"
          >
            <feather-icon
              icon="XIcon"
              svg-classes="w-4 h-4"
            />
          </button>

          <img
            :src="logo"
            class="p-2"
          >

          <label
            for="avatar-uploader"
            class="absolute w-full cursor-pointer flex py-2 justify-center items-center text-white bg-purple-800 bottom-0 left-0 mt-2 mr-2 bg-opacity-50 transition-all duration-500 ease-in-out hover:bg-opacity-100 "
          >
            <feather-icon
              icon="UploadCloudIcon"
              svg-classes="w-6 h-6"
            />
          </label>
        </div>
      </template>

      <template v-else>
        <label
          class="h-full flex justify-center items-center cursor-pointer"
          for="avatar-uploader"
        >
          <p class="font-bold leading-normal">Upload Logo</p>
        </label>
      </template>
    </div>

    <input
      id="avatar-uploader"
      ref="avatar-input"
      type="file"
      class="hidden"
      @change="onFileChange"
    >
    <p class="text-sm text-center mt-2">
      File will be resized to 256x140
    </p>
  </div>
</template>

<script>
export default {
  name: 'RjCompanyLogoUploader',

  props: {
    logo: {
      type: String,
      default: '/rj-logo.svg',
    },
  },

  methods: {
    async onFileChange() {
      await this.$nextTick();
      const file = this.$refs['avatar-input'].files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.$emit('file-uploaded', {
          file,
          fileData: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>
