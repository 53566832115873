<template>
  <rj-phone-previewer>
    <img
      :src="require('@/assets/images/phone/message-top.png')"
      :srcset="require('@/assets/images/phone/message-top.png') + ' 1x, ' + require('@/assets/images/phone/message-top@2x.png') + ' 2x'"
      alt="Safari Navigaiton Bar"
      class="iphone__keyboard"
    >
    <div class="smartphone__messages">
      <div class="smartphone__sms">
        <!-- eslint-disable -->
        <p class="break-words" v-if="sms" v-html="sms" />
        <!-- eslint-enable -->
        <!-- <a
          href="https://ihelp.bz/link"
          target="_blank"
          class="block mt-1 text-blue-500"
          rel="noreferrer noopener"
        >
          https://ihelp.bz/link
        </a> -->
      </div>
    </div>

    <img
      :src="require('@/assets/images/phone/message-bottom.png')"
      :srcset="require('@/assets/images/phone/message-bottom.png') + ' 1x, ' + require('@/assets/images/phone/message-bottom@2x.png') + ' 2x'"
      alt="Safari Toolbar"
      class="iphone__keyboard"
    >
  </rj-phone-previewer>
</template>

<script>
import RjPhonePreviewer from '@/views/common/funnels/smartphone/RjPhonePreviewer.vue';

export default {
  name: 'RjPhoneMessageScreen',

  components: { RjPhonePreviewer },

  props: {
    sms: {
      type: String,
      required: true,
    },
  },
};
</script>
