var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vs-sidebar--item",class:[
    {'vs-sidebar-item-active' : _vm.activeLink},
    {'disabled-item pointer-events-none' : _vm.isDisabled}
  ]},[(_vm.to)?_c('router-link',{class:{
      'router-link-active': _vm.activeLink,
      'pl-0': _vm.icon === ''
    },attrs:{"tabindex":"-1","exact":"","to":_vm.to,"target":_vm.target}},[(!_vm.featherIcon)?_c('vs-icon',{attrs:{"icon-pack":_vm.iconPack,"icon":_vm.icon}}):_c('feather-icon',{class:{'w-3 h-3': _vm.iconSmall},attrs:{"icon":_vm.icon}}),_vm._t("default")],2):_c('a',{staticClass:"cursor-pointer",attrs:{"target":_vm.target,"href":_vm.href,"tabindex":"-1"},on:{"!click":function($event){_vm.callback ? _vm.callback($event) : null}}},[(!_vm.featherIcon)?_c('vs-icon',{attrs:{"icon-pack":_vm.iconPack,"icon":_vm.icon}}):_c('feather-icon',{class:{'w-3 h-3': _vm.iconSmall},attrs:{"icon":_vm.icon}}),_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }