<!--
 * File Name: TheNavbarVertical.vue
 * Description: The sidenav for Main.vue.
 * Component Name: TheNavbarVertical
 * Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
 * Author: Pixinvent
 * Author URL: http://www.themeforest.net/user/pixinvent
-->

<template>
  <!-- Just wrap the v-nav-menu that was there before by passing all props. -->
  <v-nav-menu
    :logo="logo"
    :open-group-hover="openGroupHover"
    :parent="parent"
    :reduce-not-rebound="reduceNotRebound"
    :nav-menu-items="navMenuItems"
    :title="title"
  />
</template>

<script>
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue';

export default {
  name: 'TheNavbarVertical',
  components: {
    VNavMenu,
  },
  props: {
    logo: {
      type: String,
      default: '',
    },
    openGroupHover: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: String,
      default: '',
    },
    reduceNotRebound: {
      type: Boolean,
      default: true,
    },
    navMenuItems: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return { 'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark') };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // Navbar Style
    classObj() {
      if (this.verticalNavMenuWidth === 'default') {
        return 'navbar-default';
      }
      if (this.verticalNavMenuWidth === 'reduced') {
        return 'navbar-reduced';
      }
      if (this.verticalNavMenuWidth) {
        return 'navbar-full';
      }
      return '';
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    },
  },
};
</script>
